import React, { useState, useEffect }  from 'react'
import { generarIdPrueba, registrarPrueba, eliminarPruebas } from '../controllers/configuracionFirebase';
import Swal from 'sweetalert2'
import { FcAbout } from "react-icons/fc";
import { Tooltip as Mensaje } from "react-tooltip";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { pruebasAElegir } from '../controllers/administrador';

const Pruebas = ({ listPruebas }) => {

  const [ pruebas, setPruebas ] = useState([]);
  const [ prueba, setPrueba ] = useState(['']);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ requisitos, setRequisitos ] = useState([{
    edad: '',
    genero: ''
  }]);

  function eliminarPrueba(prueba, categoria, index){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 0; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de eliminar la </br> prueba?
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        const auxPruebas = [...pruebas];
        auxPruebas.slice(index, 1);
        eliminarPruebas(prueba, categoria);
        setPruebas(auxPruebas);
      }
    })
  }

  function anadirPrueba(){
    const auxPrueba = [...prueba];
    const aux = '';
    auxPrueba.push(aux);
    setPrueba(auxPrueba);
  } 

  function quitarPrueba(){
    const auxPrueba = [...prueba];
    auxPrueba.splice(auxPrueba.length - 1, 1);
    setPrueba(auxPrueba);
  }

  function agregarRequisito(){
    const requisito = {
      edad: '',
      genero: ''
    }

    const auxRequisito = [...requisitos, requisito];
    setRequisitos(auxRequisito);
  }

  function quitarRequisito(){
    const auxRequisito = [...requisitos];
    auxRequisito.splice(auxRequisito.length - 1, 1);
    setRequisitos(auxRequisito);
  }

  function onSubmitRegistrarPrueba(e){
    e.preventDefault();
    const categoria = e.target.categoria.value;
    const auxPruebas = [...pruebas];
    const auxPrueba = [];
    
    for (let i = 0; i < requisitos.length; i++) {
      requisitos[i].edad = document.getElementById(`edad${i}`).value;
      requisitos[i].genero = document.getElementById(`genero${i}`).value;
    }
    
    for (let i = 0; i < prueba.length; i++) {
      const id = generarIdPrueba();
      const prueba = document.getElementById(`prueba${i}`).value;
      
      const aux = {
        id,
        prueba,
        categoria,
        requisitos
      }
  
      auxPruebas.push(aux);
      auxPrueba.push(aux);
      setPruebas(auxPruebas);
    }
    
    registrarPrueba(auxPrueba);
    e.target.reset();

    const auxRequisito = [...requisitos];
    auxRequisito.splice(1, auxRequisito.length - 1);
    setRequisitos(auxRequisito);
    setPrueba(['']);
  }

  const acciones = (prueba, categoria, indexPrueba) => {
    return (
      <div>
        <Button icon='pi pi-times' text severity='danger' tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
        onClick={() => eliminarPrueba(prueba, categoria, indexPrueba)}/>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.requisitos} tableStyle={{ maxWidth: '400px', margin: 'auto'}} emptyMessage="No hay requisitos aún.">
        <Column field="edad" header="Edad" style={{ width: '80px'}}></Column>
        <Column field="genero" header="Genero" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  useEffect(() => {
    setPruebas(listPruebas);
  }, [setPruebas])
  
  return (
    <>
      <div className='bg-light position-relative'>
        <FcAbout
          className='position-absolute fs-3 top-0 end-0' style={{cursor: 'pointer'}}
          data-bs-toggle="modal" data-bs-target="#exampleModal3" id='ayuda'
        />
        <Mensaje
          anchorId="ayuda"
          place="bottom"
          content="Algunas ayudas para ti."
          className="bg-primary shadow"
          style={{ zIndex: 1 }}
        />
        <input type="button" className='btn btn-success mt-2' value='Añadir Prueba' onClick={() => anadirPrueba()}/>
        <input type="button" className='btn ms-2 btn-danger mt-2' value='Quitar Prueba' onClick={() => quitarPrueba()}/>
        <form onSubmit={onSubmitRegistrarPrueba} className='m-auto pt-2 pb-2' style={{maxWidth: '500px'}}>
          {prueba.map((prue, indexPrue)=>{
            return <input key={indexPrue} type="text" className='form-control mt-2' name="prueba" id={`prueba${indexPrue}`} placeholder='Prueba' required/>
          })}
          <select name="categoria" className='form-select mt-2' required>
            <option value="">Elige la categoria</option>
            {pruebasAElegir.map((prue, indexPrue)=>{
              return <option key={indexPrue} value={prue.valor}>{prue.valor}</option>
            })}
          </select>
          {requisitos.map((req, indexRe)=>{
            return <div key={indexRe}> 
              <input type="number" className='form-control mt-2' name={`edad${indexRe}`} id={`edad${indexRe}`} placeholder='Edad'/>
              <select name={`genero${indexRe}`} id={`genero${indexRe}`} className='form-select mt-2'>
                <option value="">Elige el genero</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>
              </select>
            </div>
          })}
          <input type="submit" className='btn submit mt-2' value='Añadir' />
          <input type="button" className='btn btn-primary mt-2 ms-2' value='Agregar otro Requisito' onClick={() => agregarRequisito()}/>
          <input type="button" className='btn btn-danger mt-2 ms-2' value='Quitar Requisito' onClick={() => quitarRequisito()}/>
        </form>
        <hr />
        <DataTable value={pruebas} size='small' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]} stripedRows footer={'Total pruebas: ' + pruebas.length} emptyMessage="No hay pruebas registradas aún."
        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}>
          <Column expander />
          <Column header="#" body={(_, index)=>{return index.rowIndex + 1}}></Column>
          <Column field="prueba" header="Prueba"></Column>
          <Column field="categoria" header="Categoria"></Column>
          <Column body={(prueba, indexPrueba)=>{return acciones(prueba.prueba, prueba.categoria, indexPrueba.rowIndex)}}></Column>
        </DataTable>
      </div>
      <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ayuda</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div style={{ fontFamily: "EB Garamond", fontSize: "19px", maxWidth: '800px'}} className="alert alert-success m-auto mb-2 text-start" role="alert">
                <h3 className='alert-heading'>INSTRUCCIONES</h3>
                <b>Paso 1:</b> Para comenzar, puede añadir la cantidad de pruebas que necesite utilizando el botón "Añadir Prueba". Si en algún momento desea quitar una prueba, simplemente utilice el botón "Quitar Prueba". Tenga en cuenta que este último botón eliminará la prueba más reciente que haya agregado.
                <br />
                <b>Paso 2:</b> Una vez que haya agregado las pruebas deseadas, el siguiente paso es seleccionar la categoría a la que estas pruebas pertenecen.
                <br />
                <b>Paso 3:</b> Por último, es fundamental definir los requisitos necesarios para que un deportista pueda pertenecer a esa categoría. Puede agregar la cantidad de requisitos que sean necesarios. Es esencial registrar al menos un requisito, ya que si este campo se encuentra vacío, la plataforma no reconocerá las pruebas ni la categoría asociada.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pruebas