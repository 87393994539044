import React, { useState, useEffect } from 'react';
import { registrarPlanillaRelevos, traerNadadoresRelevos, traerPlanillaRelevos } from '../controllers/configuracionFirebase';
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import Swal from 'sweetalert2'
import { Dropdown } from 'primereact/dropdown';

const Relevos = ({
  usuario,
  idTorneoParticipando,
  torneoEspecifico,
  pruebasCompletas}) => {

  const [ relevos, setRelevos ] = useState([]);
  const [ relevosParaMostrar, setRelevosParaMostrar ] = useState([]);
  const [ elegirRelevos, setElegirRelevos ] = useState(-1);
  const [ deportistaClub, setDeportistaClub ] = useState('');
  const [ clubes, setClubes ] = useState([]);
  const [ cantCarriles, setCantCarriles ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ selectedDeportista1, setSelectedDeportista1 ] = useState(null);
  const [ selectedDeportista2, setSelectedDeportista2 ] = useState(null);
  const [ selectedDeportista3, setSelectedDeportista3 ] = useState(null);
  const [ selectedDeportista4, setSelectedDeportista4 ] = useState(null);

  function onSubmit(e){
    e.preventDefault();
    const jornada = parseInt(e.target.jornada.value);
    const serie = parseInt(e.target.serie.value);
    const carril = parseInt(e.target.carril.value);
    const club = e.target.club.value;
    if(selectedDeportista1 && selectedDeportista2 && selectedDeportista3 && selectedDeportista4){
      const deportista1 = deportistas.filter((depor) => depor.nombreclub === deportistaClub)[selectedDeportista1.code];
      const deportista2 = deportistas.filter((depor) => depor.nombreclub === deportistaClub)[selectedDeportista2.code];
      const deportista3 = deportistas.filter((depor) => depor.nombreclub === deportistaClub)[selectedDeportista3.code];
      const deportista4 = deportistas.filter((depor) => depor.nombreclub === deportistaClub)[selectedDeportista4.code];
      const identificacionEquipo = [deportista1.identificacion, deportista2.identificacion, deportista3.identificacion, deportista4.identificacion];
      const nombresEquipo = [deportista1.nombre, deportista2.nombre, deportista3.nombre, deportista4.nombre];
      traerPlanillaRelevos(idTorneoParticipando, e.target.relevo.value).then((planilla)=>{
        if(planilla){
          if(!validarRelevos(identificacionEquipo)){
            if(planilla.planilla.length !== 0 && planilla.planilla[serie]){
              if(planilla.planilla[serie].nadadores[carril - 1].nombre.length !== 0){
                planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
                planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
                planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
                planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
                planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];
                const alerta = Swal.mixin({
                  toast: true,
                  showCancelButton: true
                })
                alerta.fire({
                  html: `<div class="w-100 position-relative">
                  <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
                  <p style="z-index: 10; font-family: Poppins" class="mb-0">el carril ${carril} ya esta ocupado. </br> ¿Desea reemplazar?</p>
                  </div>`,
                  confirmButtonText: 'Reemplazar',
                  confirmButtonColor: 'rgb(18, 53, 248)',
                  padding: 8,
                  cancelButtonText: 'Cancelar',
                }).then((respuesta) =>{
                  if(respuesta.isConfirmed){
                    const auxRelevosParaMostrar = [...relevosParaMostrar];
                    const relevoEncontrado = auxRelevosParaMostrar[jornada].filter((rel)=>{return rel.id === parseInt(e.target.relevo.value)});
                    if(relevoEncontrado.length !== 0){
                      planilla.planilla.forEach((pla, indexPla)=>{
                        relevoEncontrado[0].deportistas[indexPla] = pla;
                      })
                      setRelevosParaMostrar(auxRelevosParaMostrar);
                    }
                    registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
                  }
                })
              }else{
                planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
                planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
                planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
                planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
                planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];

                const auxRelevosParaMostrar = [...relevosParaMostrar];
                const relevoEncontrado = auxRelevosParaMostrar[jornada].filter((rel)=>{return rel.id === parseInt(e.target.relevo.value)});
                if(relevoEncontrado.length !== 0){
                  planilla.planilla.forEach((pla, indexPla)=>{
                    relevoEncontrado[0].deportistas[indexPla] = pla;
                  })
                  setRelevosParaMostrar(auxRelevosParaMostrar);
                }
                registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
              }
            }else{
              const auxSerie = {
                Serie: serie + 1,
                nadadores: []
              }
    
              for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
                const auxCarriles = {
                  carril: i + 1,
                  club: [],
                  edad: [],
                  identificacion: [0],
                  nombre: [],
                  nombreclub: [],
                  equipo: []
                }
    
                auxSerie.nadadores.push(auxCarriles);
              }
              planilla.planilla[serie] = auxSerie;
              planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
              planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
              planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
              planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
              planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];

              const auxRelevosParaMostrar = [...relevosParaMostrar];
              const relevoEncontrado = auxRelevosParaMostrar[jornada].filter((rel)=>{return rel.id === parseInt(e.target.relevo.value)});
              if(relevoEncontrado.length !== 0){
                planilla.planilla.forEach((pla, indexPla)=>{
                  relevoEncontrado[0].deportistas[indexPla] = pla;
                })
                setRelevosParaMostrar(auxRelevosParaMostrar);
              }
              registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
            }
          }else{
            toast.error('Se encontro un deportista repetido. Por favor verifica.');
          }
        }else{
          toast.error('El relevo no estan registrado en la planilla.');
        }
      })
    }else{
      toast.error('Debe haber 4 deportistas en el relevo.');
    }
  }

  function listadoDeportistas(){
    const auxDeportistasNombres = [];
    if(deportistas.filter((depor) => depor.nombreclub === deportistaClub).length !== 0){
      deportistas.filter((depor) => depor.nombreclub === deportistaClub).forEach((deport, indexDeport)=>{
        const objDeport = {
          name: `${deport.nombre}/${deport.edad} años/${deport.nombreclub}`,
          code: indexDeport
        };
        auxDeportistasNombres.push(objDeport);
      })
    }
    return auxDeportistasNombres;
  }

  function validarRelevos(arr) {
    const conjunto = new Set();
    for (const elemento of arr) {
      // Verificar si el elemento no es vacío
      if (elemento !== "") {
        if (conjunto.has(elemento)) {
          // Elemento duplicado encontrado
          return true;
        }
        conjunto.add(elemento);
      }
    }
    // No se encontraron elementos duplicados
    return false;
  }

  useEffect(() => {
    function sacarPruebasRelevos(){
      const relevosEncontrados = [];
      pruebasCompletas.forEach((pruebas)=>{
        const auxRelevos = [];
        pruebas.forEach((prue)=>{
          const relevo = prue.prueba.prueba.match(/Relevo/g);
          if(relevo){
            auxRelevos.push(prue);
          }
        })
        relevosEncontrados.push(auxRelevos);
      })
      setRelevos(relevosEncontrados);

      relevosEncontrados.forEach((relevosEnc)=>{
        relevosEnc.forEach((rele, indexRele)=>{
          traerPlanillaRelevos(idTorneoParticipando, rele.id + '').then((planilla)=>{
            if(planilla){
              if(planilla.planilla.length !== 0){
                planilla.planilla.forEach((pla, indexPla)=>{
                  rele.deportistas[indexPla] = pla;
                })
              }
              setRelevosParaMostrar(relevosEncontrados);
            }
          })
        })
      })
    }

    function carriles(){
      const auxCarriles = [];
      for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
        auxCarriles.push(i + 1);
      }
      setCantCarriles(auxCarriles);
    }

    carriles();
    traerNadadoresRelevos(idTorneoParticipando, setDeportistas);
    sacarPruebasRelevos();
  }, [usuario, setDeportistas, setCantCarriles, idTorneoParticipando])

  useEffect(()=>{
    function sacarClubes(){
      const auxClubes = new Set();
      deportistas.forEach((depor)=>{
        auxClubes.add(depor.nombreclub);
      })
      setClubes(Array.from(auxClubes));
    }

    sacarClubes();
  }, [deportistas])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light shadow table-responsive-sm p-2 position-relative'>
          <motion.div
            className="card m-2 pt-2 col shadow w-70 m-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}
          >
            <form onSubmit={onSubmit} className='d-flex align-items-center flex-wrap'>
              <img
                src={require(`../img/img-equipo-relevos.jpg`)}
                className="card-img-top w-50 m-auto"
                alt="imagen relevos"
              />
              <div className="card-body">
                <h3>INSCRIPCIÓN RELEVOS</h3>
                <hr />
                <div className='d-flex mb-2'>
                  <select name="jornada" className='form-select me-1' onClick={(ev) => setElegirRelevos(parseInt(ev.target.value))} required>
                    <option value={-1}>Elige la jornada</option>
                    {relevos.length !== 0 ?(
                      relevos.map((jornada, indexJornada)=>{
                        return <option key={indexJornada} value={indexJornada}>{`Jornada ${indexJornada + 1}`}</option>
                      })
                      ):(
                      <option value="">No hay jornadas</option>
                    )}
                  </select>
                  <select name="club" className='form-select' onClick={(ev) => setDeportistaClub(ev.target.value)} required>
                    <option value="">Elige el club</option>
                    {clubes.length !== 0 ?(
                      clubes.map((club, indexClub)=>{
                        return <option key={indexClub} value={club}>{club}</option>
                      })
                      ):(
                      <option value="">No hay clubes</option>
                    )}
                  </select>
                </div>
                <select name="relevo" className='form-select mb-2' required>
                  <option value="">Elige el relevo</option>
                  {elegirRelevos !== -1 ?(
                    relevos[elegirRelevos].map((rel, indexRel)=>{
                      return <option key={indexRel} value={rel.id}>{`${rel.prueba.prueba} - ${rel.categoria}`}</option>
                    })
                    ):(
                    <option value="">No hay relevos</option>
                  )}
                </select>
                <div className='d-flex mb-2'>
                  <select name="carril" className='form-select me-1' required>
                    <option value="">Elige el carril</option>
                    {cantCarriles.length !== 0 ?(
                      cantCarriles.map((carril, indexCarril)=>{
                        return <option key={indexCarril} value={carril}>{carril}</option>
                      })
                      ):(
                      <option value="">No hay carril</option>
                    )}
                  </select>
                  <select name="serie" className='form-select' required>
                    <option value="">Elige la serie</option>
                    <option value="0">1</option>
                    <option value="1">2</option>
                    <option value="2">3</option>
                    <option value="3">4</option>
                    <option value="4">5</option>
                    <option value="5">6</option>
                    <option value="6">7</option>
                    <option value="7">8</option>
                    <option value="8">9</option>
                    <option value="9">10</option>
                  </select>
                </div>
                <div className="p-inputgroup flex-1 mb-1">
                  <Dropdown value={selectedDeportista1} onChange={(e) => setSelectedDeportista1(e.value)} filter options={listadoDeportistas()} placeholder={listadoDeportistas().length + ' Deportistas Disponibles'} optionLabel='name'/>
                </div>
                <div className="p-inputgroup flex-1 mb-1">
                  <Dropdown value={selectedDeportista2} onChange={(e) => setSelectedDeportista2(e.value)} filter options={listadoDeportistas()} placeholder={listadoDeportistas().length + ' Deportistas Disponibles'} optionLabel='name'/>
                </div>
                <div className="p-inputgroup flex-1 mb-1">
                  <Dropdown value={selectedDeportista3} onChange={(e) => setSelectedDeportista3(e.value)} filter options={listadoDeportistas()} placeholder={listadoDeportistas().length + ' Deportistas Disponibles'} optionLabel='name'/>
                </div>
                <div className="p-inputgroup flex-1 mb-1">
                  <Dropdown value={selectedDeportista4} onChange={(e) => setSelectedDeportista4(e.value)} filter options={listadoDeportistas()} placeholder={listadoDeportistas().length + ' Deportistas Disponibles'} optionLabel='name'/>
                </div>
                <input
                  type="submit"
                  value="Inscribir Relevo"
                  className="btn submit mt-2"
                />
              </div>
            </form>
          </motion.div>
        </div>
        <div className='d-flex flex-wrap justify-content-center align-items-start'>
          {relevosParaMostrar.length !== 0 ?(
            relevosParaMostrar.map((jornada, indexJornada)=>{
              return jornada.map((rele, indexRele)=>{
                return <motion.div
                className={`card m-2 pt-2 shadow`}
                style={{ width: "22rem" }}
                key={indexRele}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: `0.${indexRele}5` }}
              > 
                <div className="card-body">
                  <h5 className="card-title">{rele.categoria}</h5>
                  <h6 className="card-title">{rele.prueba.prueba}</h6>
                  {rele.deportistas.length !== 0 ?(
                    rele.deportistas.map((depor, indexDepor)=>{
                      return <div key={indexDepor}>
                        <p className="card-text"><b>Serie {depor.Serie}</b></p>
                        {depor.nadadores.map((nad, indexNad)=>{
                          return <div className="accordion" id={`accordionExample${depor.Serie}${indexNad}`}>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id={`headingOne${depor.Serie}${indexNad}`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${depor.Serie}${indexNad}`} aria-expanded="false" aria-controls={`collapseOne${depor.Serie}${indexNad}`}>
                                  {nad.carril + ' ' + nad.nombreclub}
                                </button>
                              </h2>
                              <div id={`collapseOne${depor.Serie}${indexNad}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${depor.Serie}${indexNad}`} data-bs-parent={`#accordionExample${indexNad}`}>
                                <div className="accordion-body">
                                  {nad.equipo.map((eq, indexEq)=>{
                                    return <p key={indexEq} className="card-text">{eq}</p>
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                    })
                  ):(
                    <p className="card-text">No hay deportistas aún.</p>
                  )}
                </div>
              </motion.div>
              })
            })
          ):(
            <></>
          )}
        </div>
      </motion.div>
    </>
  );
}

export default Relevos;