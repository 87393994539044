import React, { useState, useEffect } from 'react';
import { traerClubes } from '../controllers/configuracionFirebase';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';

const Rifa = ({ idTorneoParticipando }) => {

  const [ clubes, setClubes ] = useState([]);
  const [ ganadoresEntrenadores, setGanadoresEntrenadores ] = useState([]);
  const [ ganadoresNadadores, setGanandoresNadadores ] = useState([]);
  const [ estadoRifa, setEstadoRifa ] = useState(true);

  function aleatorioClubs(arrClubes) {
    let num = Math.floor(Math.random() * (0 + arrClubes.length - 0) + 0);
    return num;
  }
  
  function aleatorioNadadores(arrNadadores) {
    let num = Math.floor(Math.random() * (0 + arrNadadores.length - 0) + 0);
    return num;
  }

  function clickClubs(){
    let intento1 = document.getElementById('clubs')
    let ale = 0;
    const auxClubes = [];
    const auxGanadores = [...ganadoresEntrenadores];
    const objGanador = {};
    clubes.forEach((club)=>{
      if(club.nadadores.length !== 0){
        const auxYaGano = ganadoresEntrenadores.filter((gan)=>{return gan.club === club.nombreclub});
        if(auxYaGano.length === 0){
          auxClubes.push(club);
        }
      }
    })

    if(auxClubes.length !== 0){
      if(auxClubes.length !== 1){
        const numeros = setInterval(function () {
            ale = aleatorioClubs(auxClubes);
            const entrenadorPrincipal = auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a Principal'}) : [];
            const entrenador = auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a'}) : [];
            const delegado =auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Delegado/a'}) : [];
            intento1.innerHTML = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].nombre : entrenador.length !== 0 ? entrenador[0].nombre : delegado.length !== 0 ? delegado[0].nombre : auxClubes[ale].nombreclub;
        },100);
        setTimeout(function () {

          const entrenadorPrincipal = auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a Principal'}) : [];
          const entrenador = auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a'}) : [];
          const delegado = auxClubes[ale].directivos ? auxClubes[ale].directivos.filter((dire)=>{return dire.rol === 'Delegado/a'}) : [];

          intento1.innerHTML = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].nombre : entrenador.length !== 0 ? entrenador[0].nombre : delegado.length !== 0 ? delegado[0].nombre : auxClubes[ale].nombreclub;
          clearInterval(numeros);
          objGanador.nombre = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].nombre : entrenador.length !== 0 ? entrenador[0].nombre : delegado.length !== 0 ? delegado[0].nombre : 'Sin Directivos';
          objGanador.rol = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].rol : entrenador.length !== 0 ? entrenador[0].rol : delegado.length !== 0 ? delegado[0].rol : 'Sin Directivos';
          objGanador.club = auxClubes[ale].nombreclub;
          auxGanadores.splice(0, 0, objGanador);
          setGanadoresEntrenadores(auxGanadores);
        }, 6000);
      }else{
        const entrenadorPrincipal = auxClubes[0].directivos ? auxClubes[0].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a Principal'}) : [];
        const entrenador = auxClubes[0].directivos ? auxClubes[0].directivos.filter((dire)=>{return dire.rol === 'Entrenador/a'}) : [];
        const delegado = auxClubes[0].directivos ? auxClubes[0].directivos.filter((dire)=>{return dire.rol === 'Delegado/a'}) : [];

        intento1.innerHTML = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].nombre : entrenador.length !== 0 ? entrenador[0].nombre : delegado.length !== 0 ? delegado[0].nombre : auxClubes[ale].nombreclub;
        objGanador.nombre = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].nombre : entrenador.length !== 0 ? entrenador[0].nombre : delegado.length !== 0 ? delegado[0].nombre : 'Sin Directivos';
        objGanador.rol = entrenadorPrincipal.length !== 0 ? entrenadorPrincipal[0].rol : entrenador.length !== 0 ? entrenador[0].rol : delegado.length !== 0 ? delegado[0].rol : 'Sin Directivos';
        objGanador.club = auxClubes[ale].nombreclub;
        auxGanadores.splice(0, 0, objGanador);
        setGanadoresEntrenadores(auxGanadores);
      }
    }
  }

  function clickNadadores(){
    let intento1 = document.getElementById('nadadores')
    let ale = 0;
    const auxNadadores = [];
    const auxGanadores = [...ganadoresNadadores];
    const objGanador = {};
    clubes.forEach((club)=>{
      if(club.nadadores.length !== 0){
        club.nadadores.forEach((nad)=>{
          const auxYaGano = ganadoresNadadores.filter((gan)=>{return gan.nombre === nad.nombre});
          if(auxYaGano.length === 0){
            auxNadadores.push(nad);
          }
        })
      }
    })

    auxNadadores.sort(() => Math.random() - 0.5);

    if(auxNadadores.length !== 0){
      if(auxNadadores.length !== 1){
        const numeros = setInterval(function () {
          ale = aleatorioNadadores(auxNadadores); 
          intento1.innerHTML = auxNadadores[ale].nombre; 
        },100);
        setTimeout(function () {
          intento1.innerHTML = auxNadadores[ale].nombre;
          clearInterval(numeros);
          objGanador.nombre = auxNadadores[ale].nombre;
          objGanador.club = auxNadadores[ale].nombreclub;
          auxGanadores.splice(0,0,objGanador);
          setGanandoresNadadores(auxGanadores);
        }, 6000);
      }else{
        intento1.innerHTML = auxNadadores[ale].nombre;
        objGanador.nombre = auxNadadores[ale].nombre;
        objGanador.club = auxNadadores[ale].nombreclub;
        auxGanadores.splice(0,0,objGanador);
        setGanandoresNadadores(auxGanadores);
      }
    }
  }

  const items = [
    {
      label: 'Deportistas',
      icon: 'pi pi-user',
      command: () => setEstadoRifa(false)
    },
    {
      label: 'Entrenadores',
      icon: 'pi pi-users',
      command: () => setEstadoRifa(true)
    }
  ];

  const content = (
    <div className='w-100 d-flex flex-column'>
      <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-success">
          <h2 className='mb-0'>{estadoRifa ? 'Entrenadores' : 'Deportistas'}</h2>
        </div>
      </div>
      <hr />
      <div className="ms-2 text-success">
        <h1 className='mb-0' id={estadoRifa ? 'clubs' : 'nadadores'}>PROWEBSPORTS</h1>
      </div>
    </div>
  );

  const limpiarGanador = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-times" severity="primary" raised tooltip='Borrar Ganadores' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => estadoRifa ? setGanadoresEntrenadores([]): setGanandoresNadadores([])}/>
      </div>
    );
  };

  useEffect(() => {
    traerClubes(idTorneoParticipando, setClubes);
  }, [setClubes, idTorneoParticipando])

  return (
    <>
      <div className='bg-light'>
        <div>
          <Button icon='pi pi-refresh' label='Buscar Ganador' className='btn btn-success mb-2 mt-2'
          onClick={() => estadoRifa ? clickClubs() : clickNadadores()}></Button>
          <div className="card">
            <Message
              style={{
                  border: 'solid #696cff',
                  borderWidth: '0 0 0 6px',
                  color: '#696cff'
              }}
              className="border-success justify-content-center"
              severity="success"
              content={content}
          />
          </div>
          <Toolbar left={limpiarGanador}></Toolbar>
          {estadoRifa ?(
            <DataTable value={ganadoresEntrenadores} selectionMode="single" dragSelection size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay ganadores aún." 
            tableStyle={{ minWidth: '40rem', margin: 'auto'}}>
              <Column header="#" body={(_, index)=>{return index.rowIndex + 1}}></Column>
              <Column field="nombre" header="Nombre" style={{ fontWeight: 'bold' }}></Column>
              <Column field="rol" header="Rol"></Column>
              <Column field="club" header="Equipo"></Column>
            </DataTable>
          ):(
            <DataTable value={ganadoresNadadores} selectionMode="single" dragSelection size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay ganadores aún." 
            tableStyle={{ minWidth: '40rem', margin: 'auto'}}>
              <Column header="#" body={(_, index)=>{return index.rowIndex + 1}}></Column>
              <Column field="nombre" header="Deportista" style={{ fontWeight: 'bold' }}></Column>
              <Column field="club" header="Equipo"></Column>
            </DataTable>
          )}
        </div> 
        <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
        <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={80} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      </div>
    </>
  );
}

export default Rifa;