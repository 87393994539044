import React from 'react';
import { motion } from "framer-motion";

function SobreNosotros() {
  return (  
    <div>
      <motion.div className='d-flex justify-content-around align-items-center flex-wrap'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div style={{width: '27%'}}>
          <img className='w-100' src={require('../img/img-logo.webp')} alt="Logo orcas valle" /> 
        </div>
        <div>
          <div className='w-100'>
            <h4 style={{fontFamily: 'Merriweather Sans'}}>CLUB DEPORTIVO <br />
            ESCUELA DE NATACIÓN</h4>
          </div>
          <div className='w-100'>
            <h1 style={{fontSize: '53px', color: 'rgb(47, 157, 247)', textShadow: '-1px 1px 1px black', fontFamily: 'Abril Fatface'}}>ORCAS VALLE</h1>
          </div>
          <div className='w-100'>
            <p
            >Nit: 6.253.227-2 <br />
            Resolución 001/25 de mayo del 2010 <br />
            IMDER FLORIDA <br />
            Resolución 001/08 de enero del 2013 <br />
            LIGA VALLECAUCANA DE NATACIÓN <br />
            Fundación deportiva orcas-valle <br />
            NIT: 901592409-6 <br />
            FLORIDA VALLE</p>
          </div>
        </div>
        <div>
          <div className='w-50 m-auto'>
            <img className='w-100' src={require('../img/img-fecna.webp')} alt="logo afiliacion fecha" />
          </div>
          <div className='w-50 m-auto'>
            <img className='w-100' src={require('../img/img-logo-liga.webp')} alt="logo afiliacion liga valle del cauca" />
          </div>
        </div>
      </motion.div>
      {/* ----------------- end section 1 -------------------------------------
          ----------------- start section 2 -------------------------------------*/
      }
      <motion.div className='m-4'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='' style={{textAlign: 'justify'}}>
          <div className='text-center'>
            <h1 style={{fontFamily: 'Abril Fatface'}}>SOBRE NOSOTROS</h1>
          </div>
          <div className='m-auto' style={{maxWidth: '1500px'}}>
          <p style={{fontFamily: 'Merriweather Sans'}}>
          En SAN ANTONIO de los caballeros corregimiento de FLORIDA, en el valle del cauca el 28 de noviembre de 2008, se da inicio al gran sueño.
          <br />
          “Crear una escuela de natación” realizando así la convocatoria, encontrando una gran acogida por parte de toda la comunidad. Desde ese día el corregimiento de SAN ANTONIO cuenta por primera vez con una escuela de formación deportiva, la acogida fue tan grande que inicialmente el club empezó con más de 60 Inscritos, los padres se mostraban muy interesados en fomentar en sus hijos el amor hacia el deporte acuático, la recepción fue tan grande que la misma comunidad se encargó de divulgar los logros conseguidos por los alumnos en el club.
          <br />
          Inicialmente el club nació con el nombre de <b> ESNADEL “ESCUELA DE NATACIÓN DELFINES” </b> y se trabajó con esa identidad por un año, después de este periodo se toma la decisión de trasladar la escuela de escenario deportivo.
          <br />
          Debido a la gran afluencia de alumnos las instalaciones del parque ya eran muy pequeñas para el buen desarrollo de sus alumnos, por lo tanto se toma la decisión de trasladar el club al municipio de florida valle del cauca, realizándose un convenio con el parque recreacional CONFAMDI, facilitándonos así sus instalaciones.
          <br />
          Pasados unos meses se busca el apoyo de las empresas privadas de las públicas y sobre todo el reconocimiento del instituto municipal del deporte y recreación “IMDER”, por esta razón se toma la decisión de cambiar la razón social y pasamos de <b> ESNADEL “ESCUELA DE NATACION DELFINES” a CLUB ESCUELA DE NATACION ORCAS VALLE” </b> , en esa búsqueda de reconocimiento entendimos que podíamos fundar un club y una escuela juntas ya que esto nos permitiría hacer presencia deportiva ante cualquier club y así se nos daría la oportunidad de demostrar los avances deportivos con nuestros alumnos, llevándolos a participar en competencias a nivel clubes, es así que el IMDER nos brinda el reconocimiento, brindándonos la <b> RESOLUCIÓN 001 del 25 de mayo del 2010 como CLUB ESCUELA DE NATACIÓN ORCAS VALLE con el NIT 6.253.227-2. </b>
          <br />
          El club ha recibido una gran acogida por toda la comunidad floridana y sus alrededores al demostrar ante los demás municipios del valle que en FLORIDA se cuenta con un enorme potencial deportivo teniendo un gran eco ante los clubes de natación del departamento y el país.
          <br />
          Una vez se estableció un equipo formado y deber la gran demanda deportiva y las aspiraciones de los alumnos se decide afiliarse a la liga vallecaucana de natación, donde nos enviaron su auditoria y corroborando la existencia de nuestro club y dándonos su reconocimiento, <b> RESOLUCIÓN 001/08 de enero del 2013 LIGA VALLECAUCANA DE NATACIÓN </b>, y la afiliación a la federación colombiana de natación <b> (FECNA) </b> a través de la ficha Fecna inscribiendo nuestro deportistas, y por fin la oportunidad de rankear nuestro deportistas, en el año 2022 se establece la <b> FUNDACIÓN DEPORTIVA ORCAS VALLE con No de NIT: 901592409-6 Florida valle </b>, para así lograr el apoyo de las empresas privadas para nuestros deportistas.
          </p>
          </div>
        </div>
      </motion.div>
      {/* ----------------- end section 2 -------------------------------------
          ----------------- start section 3 -------------------------------------*/
      }
      <div className='m-3'>
        <motion.div className='d-flex justify-content-start align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-50' style={{maxWidth: '400px'}}>
            <img className='w-100' src={require('../img/vision.webp')} alt="logo vision" />
          </div>
          <div className='w-100' style={{maxWidth: '500px'}}>
            <h3 style={{fontFamily: 'Abril Fatface'}}>Visión:</h3>
            <p style={{fontFamily: 'Merriweather Sans'}}>
            Ser la Escuela de Natación más distinguida en cuanto calidad y disfrute en las clases de natación para todos sus alumnos, desarrollando en ellos una conciencia de la importancia del deporte, ser una organización reconocida a nivel nacional.
            </p>
          </div>
        </motion.div>
        <motion.div className='d-flex justify-content-end align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-100' style={{maxWidth: '500px'}}>
            <h3 style={{fontFamily: 'Abril Fatface'}}>Misión:</h3>
            <p style={{fontFamily: 'Merriweather Sans'}}>
            Nuestra responsabilidad es muy directa por esta razón nos preocupamos en formar jóvenes talentos fomentando la disciplina, criterios de superación y competición. Que el día a día de nuestros alumnos sea de aprendizaje ya sea de las competencias acuáticas como de las vivencias personales ya que para el “CLUB ESCUELA DE NATACION ORCAS VALLE” es de vital importancia formar jóvenes con valores que puedan poner en práctica en su diario vivir y en cada competencia.
            </p>
          </div>
          <div className='w-50' style={{maxWidth: '400px'}}>
            <img className='w-100' src={require('../img/mision.webp')} alt="logo mision" />
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default SobreNosotros;