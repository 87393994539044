import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IoLogoWhatsapp, IoMdContacts, IoMdMail, IoMdPerson } from 'react-icons/io'
import { datosAdmin } from '../controllers/administrador';

const Conctatenos = () => {

  const anioAutor = new Date();

  return (
    <motion.div className='w-100 text-start'
    style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.2), rgba(47, 157, 247, 0.3))'}}
    initial={{opacity: 0}}
    whileInView={{opacity: 1}}
    transition={{duration: 1, delay: 0.3}}
    >
      <h4 className='p-3 mb-0' style={{fontFamily: 'Poppins'}}>No dudes en comunicarte con nosotros para cualquier asistencia que necesites.</h4>
      <div className='d-flex flex-wrap justify-content-around'>
        <div className='m-3' style={{maxWidth: '200px'}}>
          <img className='w-100' src={require('../img/img-logo-swimmingt.webp')} alt="img logo swimming" />
        </div>
        <div className='m-3'>
          <div className='d-flex justify-content-start align-items-center'>
            <img className='me-1' style={{width: '40px'}} src={require('../img/img_logo.png')} alt="logo" />
            <h5>{datosAdmin.nombre}</h5>
          </div>
          <div className='d-flex flex-column'>
            <Link to={`mailto:${datosAdmin.email}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoMdMail className='fs-2 text-secondary'/> {datosAdmin.email}</Link>
            <Link to={`https://wa.me/57${datosAdmin.celCorto}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoWhatsapp className='fs-2 text-success'/> +57 {datosAdmin.cel}</Link>
          </div>
          <p className='mt-4'>© 2023 - {anioAutor.getFullYear()} prowebsports</p>
        </div>
        <div className='m-3'>
          <h6><IoMdContacts className='fs-2 text-info'/>Equipo</h6>
          <div className='d-flex flex-column'>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/> Admon. {datosAdmin.encargado}.</p>
            <Link to={`https://wa.me/57${datosAdmin.celCorto}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 {datosAdmin.cel}</Link>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/>Ing. Emanuel Barbosa Labrada.</p>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 324-588-4041</Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Conctatenos;