import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { consolidado } from '../controllers/configuracionFirebase';
import { acomodarEdadesMaster, acomodarEdadesPersonalizadas } from '../controllers/completarPruebas';
import { Avatar } from 'primereact/avatar';
import { categoriasSinRelevos } from '../controllers/administrador';

const Consolidado = ({ torneoEspecifico, idTorneoParticipando }) => {

  const [ clubes, setClubes ] = useState([]);
  const [ varones, setVarones ] = useState([]);
  const [ damas, setDamas ] = useState([]);
  const [ totales, setTotales ] = useState([]);

  function edadesGenero(genero, categoria, edad){
    let auxEdad = genero === 'Femenino' ? acomodarEdadesPersonalizadas(edad) + 'D' : genero === 'Masculino' ? acomodarEdadesPersonalizadas(edad) + 'V' : acomodarEdadesPersonalizadas(edad) + 'M';
    /*
    switch (genero) {
      case 'Femenino':
        break;
      case 'Masculino':
        break;
      default:
        break;
    }
    */
    return auxEdad;
  }

  function sumatoria(nadadores, arrGenero){
    
    if(arrGenero.categoria !== 'abierta' && arrGenero.categoria !== 'master' && 
    arrGenero.categoria !== 'mayores' && arrGenero.categoria !== 'infantilAJuntos' &&
    arrGenero.categoria !== 'infantilBJuntos' && arrGenero.categoria !== 'juvenilAJuntos' &&
    arrGenero.categoria !== 'juvenilBJuntos' && arrGenero.categoria !== 'menores5y6' && 
    arrGenero.categoria !== 'menores7y8' && arrGenero.categoria !== 'menores9y10' && 
    arrGenero.categoria !== 'infantil11y12' && arrGenero.categoria !== 'infantil13y14' && 
    arrGenero.categoria !== 'juvenil15y16' && arrGenero.categoria !== 'juvenil17ymayores'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if(nad.edad === arrGenero.edad && nad.genero === arrGenero.genero){
          contador++;
        }
      })

      return contador;
    }

    if(arrGenero.categoria === 'mayores'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if((nad.edad >= arrGenero.edad && nad.edad <= 24) && nad.genero === arrGenero.genero){
          contador++;
        }
      })

      return contador;
    }

    if(arrGenero.categoria === 'abierta'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if((nad.edad >= 14 || nad.edad >= 15) && nad.genero === arrGenero.genero){
          contador++;
        }
      })

      return contador;
    }

    if(arrGenero.categoria === 'infantilAJuntos' || arrGenero.categoria === 'infantilBJuntos'
    || arrGenero.categoria === 'juvenilAJuntos' || arrGenero.categoria === 'juvenilBJuntos'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if(((nad.edad === arrGenero.edad[0] || nad.edad === arrGenero.edad[1]) && nad.genero === arrGenero.genero)){
          contador++;
        }
      })

      return contador;
    }
    
    if(arrGenero.categoria === 'menores5y6' || arrGenero.categoria === 'menores7y8'
    || arrGenero.categoria === 'menores9y10' || arrGenero.categoria === 'infantil11y12'
    || arrGenero.categoria === 'infantil13y14' || arrGenero.categoria === 'juvenil15y16'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if(((nad.edad === arrGenero.edad[0] || nad.edad === arrGenero.edad[1]) && nad.genero === arrGenero.genero)){
          contador++;
        }
      })

      return contador;
    }

    if(arrGenero.categoria === 'juvenil17ymayores'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if(((arrGenero.edad.includes(nad.edad)) && nad.genero === arrGenero.genero)){
          contador++;
        }
      })

      return contador;
    }

    if(arrGenero.categoria === 'master'){
      let contador = 0;
      nadadores.forEach((nad)=>{
        if(((nad.edad >= arrGenero.edad && nad.edad <= (arrGenero.edad + 4)) && nad.genero === arrGenero.genero)){
          contador++;
        }
      })

      return contador;
    }
  }

  useEffect(() => {
    if(idTorneoParticipando){
      consolidado(idTorneoParticipando, setClubes);
    }
  }, [setClubes, idTorneoParticipando])

  useEffect(() => {
    function sacarPruebas(){
      if(torneoEspecifico.length !== 0){
        const auxCategorias = [];
        const auxVarones = new Set();
        const auxDamas = new Set();
        torneoEspecifico.jornadas.forEach((jor)=>{
          jor.categoria.forEach((cate)=>{
            categoriasSinRelevos.forEach((cateArr)=>{
              if(cate === cateArr.nombre){
                cateArr.arreglo.forEach((arr)=>{
                  arr.categoria = cate;
                })
                auxCategorias.push(...cateArr.arreglo);
              }
            })
          })
        })
        auxCategorias.forEach((auxCate)=>{
          if(auxCate.genero === 'Femenino'){
            auxDamas.add(auxCate);
          }
          if(auxCate.genero === 'Masculino'){
            auxVarones.add(auxCate);
          }
        })
        const varones = Array.from(auxVarones);
        const damas = Array.from(auxDamas);
        
        let contadorGeneral = 0;
        const auxTotales = [];
        const auxTotalesClub = [];
        const auxGeneros = [...damas, ...varones];
        clubes.forEach((club)=>{
          let contadorClub = 1;
          auxGeneros.forEach((auxGen, indexAuxGen)=>{
            contadorGeneral = sumatoria(club.nadadores, auxGen);
            if(auxTotales[indexAuxGen]){
              auxTotales[indexAuxGen] += contadorGeneral;
              contadorClub++;
            }else{
              auxTotales[indexAuxGen] = contadorGeneral;
            }
          })
          auxTotalesClub.push(contadorClub);
        })
        setTotales(auxTotales);
        setVarones(varones);
        setDamas(damas);
      }
    }
    sacarPruebas();
  }, [clubes, torneoEspecifico])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      className='pb-3'
      >
        <div className='bg-light p-2 shadow table-responsive-xl'>
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th className="titulo-consolidado" colSpan={50}>ESTADISTICAS DE CATEGORIAS</th>
              </tr>
              <tr>
                <th className="damas" colSpan={damas.length + 1}>Damas</th>
                <th className="varones" colSpan={varones.length + 5}>Varones</th>
              </tr>
              <tr>
                <th className="clubes">Clubes</th>
                {
                  damas.map(function(cate, indexCate){
                    return <th className="categorias-damas" key={indexCate}>{edadesGenero(cate.genero, cate.categoria, cate.edad)}</th>
                  })
                }
                {
                  varones.map(function(cate, indexCate){
                    return <th className="categorias-varones" key={indexCate}>{edadesGenero(cate.genero, cate.categoria, cate.edad)}</th>
                  })
                }
                <th className="total">Total</th>
              </tr>
            </thead>
            <tbody>
                {
                  clubes.map(function(club, indexClub){
                    return club.nadadores.length !== 0 ?(
                    <tr key={indexClub}>
                      <td className="nombreclub">{club.nombreclub}</td>
                      {damas.length !== 0 ? (
                        damas.map((dam, indexDam)=>{
                          return <td key={indexDam}>{sumatoria(club.nadadores, dam)}</td>
                        })
                      ):(
                        <></>
                      )}

                      {varones.length !== 0 ? (
                        varones.map((varo, indexVaro)=>{
                          return <td key={indexVaro}>{sumatoria(club.nadadores, varo)}</td>
                        })
                      ):(
                        <></>
                      )}
                      <td className="rojo">{club.nadadores.length}</td>
                    </tr>
                    ):(
                      <></>
                    )
                  })
                }
                {
                  <tr>
                    <td className="total total-clubes">Total</td>
                    {totales.length !== 0 ?(
                      totales.map((tol, indexTol)=>{
                        return <td key={indexTol}>{tol}</td>
                      })
                    ):(
                      <></>
                    )}
                    <th className="total-1">{totales.reduce((acumulado, valor) => acumulado + valor, 0)}</th>
                  </tr>
                }
            </tbody>
          </table>
        </div>
        <div className='d-flex justify-content-around flex-wrap mt-4'>
          {clubes.map((club, indexClub)=>{
            return club.nadadores.length !== 0 ?(
              <motion.div key={indexClub}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{duration: 1, delay: `0.${indexClub}`}}
              >
                <Avatar className="flex align-items-center justify-content-center mr-2" image={club.photo} label={club.photo !== null && club.photo.endsWith(".pdf") ? '' : club.nombreclub[0] + club.nombreclub[1]} size="xlarge"/>
            </motion.div>
            ):(
              <></>
            )
          })}
        </div>
      </motion.div>
    </>
  );
}

export default Consolidado;