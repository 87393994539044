import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from '@react-google-maps/api';

const Mapa = ({ direccion, setMostrarMensaje, tituloEvento, setDetallesMapa, usuario }) => {

  const [ ubicacionActual, setUbicacionActual] = useState(null);
  const [ ubicacionEvento, setUbicacionEvento] = useState(null);

  const mapStyles = {
    height: "84%",
    width: "100%"
  };

  useEffect(() => {
    // Solicitar permiso al usuario para acceder a la ubicación
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        setUbicacionActual({ lat: latitude, lng: longitude });
      },
      error => {
        console.error('Error al obtener la ubicación:', error);
      }
    );
    
    navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
      if (permissionStatus.state === 'granted') {
        // Si el permiso ya está concedido, obtener la ubicación actual del dispositivo

        setMostrarMensaje(false);
      } else if (permissionStatus.state === 'denied') {
        // Si el permiso ha sido denegado, mostrar un mensaje al usuario
        setMostrarMensaje(true);
      }
    });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: direccion }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const { location } = results[0].geometry;
          setUbicacionEvento({lat: location.lat(), lng: location.lng()});
        } else {
          console.log('No se encontraron resultados para esa dirección.');
        }
      } else {
        console.log('Hubo un error al buscar la dirección.');
      }
    });
  }, []);
  
  useEffect(() => {
    if(ubicacionActual && ubicacionEvento){
      const service = new window.google.maps.DistanceMatrixService();
      const origen = new window.google.maps.LatLng(ubicacionActual.lat, ubicacionActual.lng);
      const destino = new window.google.maps.LatLng(ubicacionEvento.lat, ubicacionEvento.lng);

      const request = {
        origins: [origen],
        destinations: [destino],
        travelMode: 'DRIVING',
      };

      // Hacer la solicitud al servicio de matriz de distancias
      service.getDistanceMatrix(request, (response, status) => {
        if (status === 'OK') {
          const distancia = response.rows[0].elements[0].distance.text; // Distancia en texto (por ejemplo, "50 km")
          const tiempo = response.rows[0].elements[0].duration.text; // Tiempo de viaje en texto (por ejemplo, "1 hora 30 mins")
        
          setDetallesMapa({distancia, tiempo});
        } else {
          console.error('Error al obtener la matriz de distancias:', status);
        }
      });
    }
  },[ubicacionActual, ubicacionEvento])

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={ubicacionActual ? 10 : 15}
      center={ubicacionEvento || {lat: 3.4110616973736616, lng: -73.31416571428842}}
    >
      {ubicacionActual && (<Marker position={ubicacionActual} label={{
        text: usuario.nombreclub,
        color: 'black', // Color del texto de la etiqueta
        fontSize: '16px', // Tamaño de la fuente de la etiqueta
        fontWeight: 'bold',// Grosor de la fuente de la etiqueta
      }}/>)}
      {ubicacionEvento && (<Marker position={ubicacionEvento} label={{
        text: tituloEvento,
        color: 'black', // Color del texto de la etiqueta
        fontSize: '16px', // Tamaño de la fuente de la etiqueta
        fontWeight: 'bold' // Grosor de la fuente de la etiqueta
      }}/>)}
    </GoogleMap>
  );
}

export default Mapa;