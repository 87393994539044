import React, { useState, useEffect } from 'react';
import { registrarTablasPlanilla, sacarNadadoresDeLosClubs, traerCategorias } from '../controllers/configuracionFirebase';
import TablaPlanilla from './TablaPlanilla'
import { motion } from "framer-motion";
import { toast } from 'react-hot-toast';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { pdfPapeletas, pdfPlanilla, pdfPlanillaTiempos } from '../controllers/pdfs';
import { Button } from 'primereact/button';
import { fetchData } from '../controllers/tiempoLimiteInscripcion';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';

const Planilla = ({ 
  idTorneoParticipando,
  pruebasCompletas, 
  torneoEspecifico,
  planilla,
  usuario,
  fechaRegistroProgramacion}) => {

  const [ nadadoresCarriles, setNadadoresCarriles ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ pdfUrl, setPdfUrl] = useState();
  const [ dialogCarriles, setDialogCarriles ] = useState(false);
  const [ carriles, setCarriles ] = useState([]);
  const [ fechaHoraRegistro, setFechaHoraRegistro ] = useState('');

  function descargar(){
    saveAs(pdfUrl, `programacion ${datosAdmin.nombre}.pdf`);
  }

  function registrarTodasPlanillas(){
    const auxPruebas = [];
    pruebasCompletas.forEach((prueCompl)=>{
      prueCompl.forEach((prue)=>{
        auxPruebas.push(prue);
      })
    })
    
    for (let i = 0; i < auxPruebas.length; i++) {
      registrarTablasPlanilla(idTorneoParticipando, auxPruebas[i], nadadoresCarriles.length !== 0 ? nadadoresCarriles[i] : [], fechaHoraRegistro);
    }
    toast.success('Todas las tablas se estan registrado...\nEsto puede tardar unos segundos o minutos.');
  }
  
  function revolver(){
    traerCategorias(pruebasCompletas, setNadadoresCarriles, deportistas, parseInt(torneoEspecifico.carril), torneoEspecifico);
  }

  function crearPapeletas(){
    const auxPapeletas = toast.loading('Creando papeletas...\nEsto puede tardar unos segundos o minutos.');
    setTimeout(function() {
      pdfPapeletas(planilla, setPdfUrl, pruebasCompletas, usuario, auxPapeletas);
    }, 500);
  }

  function crearPlanillaTiempos(e){
    e.preventDefault();
    const carril = e.target.carril.value;
    pdfPlanillaTiempos(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, false, parseInt(carril));
    //setDialogCarriles(false);
  }

  const items = [
    {
      label: 'Programación .pdf Marcada',
      icon: 'pi pi-palette',
      command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, true)
    },
    {
        label: 'Programación Llamados .pdf',
        icon: 'pi pi-microphone',
        command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, true, false)
    },
    {
        label: 'Programación .pdf',
        icon: 'pi pi-file-pdf',
        command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, false)
    },
    {
      label: 'Programación Tiempos .pdf',
      icon: 'pi pi-stopwatch',
      command: () => setDialogCarriles(true)
    },
    {
        label: 'Papeletas .pdf',
        icon: 'pi pi-id-card',
        command: () => crearPapeletas()
    },
    {
        label: 'Revolver',
        icon: 'pi pi-sync',
        command: () => revolver()
    },
    {
        label: 'Registrar Programación',
        icon: 'pi pi-pencil',
        command: () => registrarTodasPlanillas()
    },
  ];
  
  useEffect(() => {
    sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
  }, [setDeportistas])

  useEffect(() => {
    traerCategorias(pruebasCompletas, setNadadoresCarriles, deportistas, parseInt(torneoEspecifico.carril), torneoEspecifico);
  }, [deportistas])

  useEffect(()=>{
    const auxCarriles = [];
    for (let i = 1; i <= parseInt(torneoEspecifico.carril); i++) {
      auxCarriles.push(i);
    }
    setCarriles(auxCarriles);
  },[torneoEspecifico])

  useEffect(() =>{
    fetchData().then((fech)=>{

      const date = new Date(fech);
      // Extraer y formatear la fecha y la hora
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-11
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      // Crear la cadena en el formato deseado
      const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
      setFechaHoraRegistro(formattedDateTime);
    })
  },[])

  return (
    <div className='position-relative'>
      <motion.div
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="alert alert-info alert-dismissible fade show text-center mb-0" role="alert">
          <h4>¡ULTIMO REGISTRO PROGRAMACIÓN!</h4>
          <p className="mb-0">{fechaRegistroProgramacion}</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        {pruebasCompletas.lenght !== 0 ?(
          pruebasCompletas.map((prue, indexPrue)=>{
            return <TablaPlanilla key={indexPrue} jornada={indexPrue + 1} pruebas={prue} nadadores={nadadoresCarriles.length !== 0 ? nadadoresCarriles : []} idTorneoParticipando={idTorneoParticipando} usuario={usuario} fechaHoraRegistro={fechaHoraRegistro}/>
          })
          ):(
            <></>
          )}
      </motion.div>
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={100} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Programación`} modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
          <Button icon="pi pi-download" label='Descargar Programación' className='p-2 btn btn-success' onClick={() => descargar()}></Button>
        </div>
      </Dialog>
      <Dialog visible={dialogCarriles} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Programación Tiempos`} modal className="p-fluid" onHide={() => setDialogCarriles(false)}>
        <div className="field">
          <form onSubmit={crearPlanillaTiempos}>
            <div className="alert alert-success alert-dismissible fade show text-start" role="alert">
              <h4>¡IMPORTANTE!</h4>
              <p className="mb-0">Selecciona el carril que quieras que aparezca en la programación de tiempos. Puedes optar por mostrar todos los carriles o solo uno en particular.</p>
            </div>
            <div className="form-floating mb-2">
              <select name="carril" id="carril" className='form-select' aria-label="Default select example" required>
                <option value="0">Todos</option>
                {carriles.length !== 0 ?(
                  carriles.map((carr, indexCarr)=>{
                    return <option key={indexCarr} value={carr}>{carr}</option>
                  })
                ):(
                  <></>
                )}
              </select>
              <label htmlFor="carril">Carril</label>
            </div>
            <Button type='submit' icon='pi pi-check' label='Generar PDF' className='btn btn-success'></Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
}

export default Planilla;