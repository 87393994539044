import React, { useState, useEffect } from 'react'
import { traerRanking } from '../controllers/configuracionFirebase';
import { motion } from "framer-motion";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { arreglarTiempoEstandar } from '../controllers/pdfs';
import { edadesRanking } from '../controllers/administrador';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';

const Ranking = () => {

  const [ ranking, setRanking ] = useState([]);
  const [ resultadoBusqueda, setResultadoBusqueda ] = useState([]);
  const [ tiempos, setTiempos ] = useState([]);
  const [ pruebaElegida, setPruebaElegida ] = useState('');
  const [ edadElegida, setEdadElegida ] = useState([]);
  const [ opcionesElegidos, setOpcionesElegidos ] = useState([]);
  const [ generoElegido, setGeneroElegido ] = useState('');
  const [ departamentoElegido, setDepartamentoElegido ] = useState('');
  const [ fechaInicialElegida, setFechaInicialElegida ] = useState('');
  const [ fechaFinElegida, setFechaFinElegida ] = useState('');
  const departamentosColombia = [
    "Amazonas",
    "Antioquia",
    "Arauca",
    "Atlántico",
    "Bolívar",
    "Boyacá",
    "Caldas",
    "Caquetá",
    "Casanare",
    "Cauca",
    "Cesar",
    "Chocó",
    "Córdoba",
    "Cundinamarca",
    "Guainía",
    "Guaviare",
    "Huila",
    "La Guajira",
    "Magdalena",
    "Meta",
    "Nariño",
    "Norte de Santander",
    "Putumayo",
    "Quindío",
    "Risaralda",
    "San Andrés y Providencia",
    "Santander",
    "Sucre",
    "Tolima",
    "Valle del Cauca",
    "Vaupés",
    "Vichada",
  ];

  function sacarPruebas(){
    const auxPruebas = [
      '50 Libre',
      '100 Libre',
      '200 Libre',
      '400 Libre',
      '800 Libre',
      '1500 Libre',

      '50 Espalda',
      '100 Espalda',
      '200 Espalda',

      '50 Pecho',
      '100 Pecho',
      '200 Pecho',

      '50 Mariposa',
      '100 Mariposa',
      '200 Mariposa',

      '200 Combinado',
      '400 Combinado',
    ];

    return auxPruebas;
  }

  function sacarEdadesElegidas(e){
    let auxEdadElegidas = [...edadesRanking];
    let auxOpcionesElegidas = [...opcionesElegidos];

    if(e.checked){
      auxOpcionesElegidas.push(e.value);
    }else{
      auxEdadElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setEdadElegida(auxEdadElegidas);
    setOpcionesElegidos(auxOpcionesElegidas);
  }

  function reducirPalabraDepartamento(palabra){
    const arrPalabra = palabra.split('');
    if(arrPalabra[0] && arrPalabra[1] && arrPalabra[2] && arrPalabra[3] && arrPalabra[0]){
      return `${arrPalabra[0] + arrPalabra[1] + arrPalabra[2] + arrPalabra[3] + arrPalabra[4]}`;
    }

    if(arrPalabra[0] && arrPalabra[1] && arrPalabra[2] && arrPalabra[3]){
      return `${arrPalabra[0] + arrPalabra[1] + arrPalabra[2] + arrPalabra[3]}`;
    }
  }
  
  useEffect(() => {
    function rangoFechaEventos(fechaRecord){
      const auxfechaRecord = new Date(fechaRecord);
      const auxfechaInicial = new Date(fechaInicialElegida);
      const auxfechaFin = new Date(fechaFinElegida);
      let fechaEnElrango = false;
      if(auxfechaRecord >= auxfechaInicial && auxfechaRecord <= auxfechaFin){
        fechaEnElrango = true;
      }
      return fechaEnElrango;
    }
    
    const obtenerRecordMenor = (deportista, prueba) => {
      const recordDeportista = deportista.pruebas.find(
        (pruebaDeportista) => pruebaDeportista.prueba === prueba && rangoFechaEventos(pruebaDeportista.fecha)
      );
    
      if (recordDeportista) {
        const record = recordDeportista.record.split(":").map(Number);
        const totalMilisegundos = record[0] * 60 * 1000 + record[1] * 1000 + record[2];
    
        if (totalMilisegundos === 0) {
          return Infinity;
        }
    
        return totalMilisegundos;
      }
    
      return Infinity; // Si no hay registro, lo consideramos infinito para que aparezca al final de la lista
    };

    function sacarGrupo(prueba, edad, genero, departamento){
      const auxNad = [];
      if(edad.length !== 0){
        edad.forEach((ed)=>{
          auxNad.push(...ranking[ed]);
        })
      }else{
        edadElegida.forEach((ed)=>{
          auxNad.push(...ranking[ed.edad]);
        })
      }
      if(auxNad.length !== 0){
        const auxRanking = [...auxNad];
        let auxDeportistas = [];
        if(departamento === 'todos'){
          auxDeportistas = auxRanking.filter((auxRan)=>{return auxRan.genero === genero && auxRan.pruebas.some(prue => rangoFechaEventos(prue.fecha) && prue.prueba === pruebaElegida)});
        }else{
          auxDeportistas = auxRanking.filter((auxRan)=>{return auxRan.genero === genero && auxRan.departamento === departamento && auxRan.pruebas.some(prue => rangoFechaEventos(prue.fecha) && prue.prueba === pruebaElegida)});
        }
    
        const auxDeportPruebas = [];
        const tiempo = [];
    
        auxDeportistas.sort((a, b) => {
          const recordA = obtenerRecordMenor(a, prueba);
          const recordB = obtenerRecordMenor(b, prueba);
          return recordA - recordB;
        });
    
        auxDeportistas.forEach((auxDepor)=>{
          auxDepor.pruebas.forEach((prue)=>{
            if(prue.prueba === prueba && prue.record !== '0:0:0'){
              tiempo.push(prue);
              auxDeportPruebas.push(auxDepor);
            }
          })
        })
    
        setResultadoBusqueda(auxDeportPruebas);
        setTiempos(tiempo);
      }
    }

    sacarGrupo(pruebaElegida.trim(), opcionesElegidos, generoElegido, departamentoElegido);
  }, [opcionesElegidos, generoElegido, departamentoElegido, pruebaElegida, ranking, fechaFinElegida, fechaInicialElegida])

  useEffect(() => {
    traerRanking(setRanking);
  }, [])

  return (
    <>
      <motion.div className="bg-light"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <h2>RANKING PROWEBSPORTS</h2>
        </div>
        <div className='d-flex flex-wrap justify-content-center'>
          <div className="form-floating me-1">
            <input className='form-control mb-2' type="date" name="fechaInicial" id='fechaInicial' onChange={(ev) => setFechaInicialElegida(ev.target.value)}/>
            <label htmlFor="fechaInicial">Fecha Inicial</label>
          </div> 
          <div className="form-floating me-1">
            <input className='form-control mb-2' type="date" name="fechaFin" id='fechaFin' onChange={(ev) => setFechaFinElegida(ev.target.value)}/>
            <label htmlFor="fechaFin">Fecha Fin</label>
          </div> 
          <div className="form-floating me-1">
            <select name="prueba" id="prueba" className='form-select' style={{maxWidth: '250px'}} onChange={(ev) => setPruebaElegida(ev.target.value)}>
              <option value="">Elige la prueba</option>
              {sacarPruebas().map((prue, indexPrue)=>{
                return <option key={indexPrue} value={prue}>{prue}</option>
              })}
            </select>
            <label htmlFor="prueba">Prueba</label>
          </div>
          <div className="form-floating me-1">
            <select className='form-select' name="genero" id="genero" style={{maxWidth: '250px'}} onChange={(ev) => setGeneroElegido(ev.target.value)}>
              <option value="">Elige</option>
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
            </select>
            <label htmlFor="genero">Género</label>
          </div>
          <div className="form-floating">
            <select className='form-select' name="departamento" id="departamento" style={{maxWidth: '250px'}} onChange={(ev) => setDepartamentoElegido(ev.target.value)}>
              <option value="">Elige el departamento</option>
              <option value="todos">Todos</option>
              {departamentosColombia.map((depar, indexDepar)=>{
                return <option key={indexDepar} value={depar}>{depar}</option>
              })}
            </select>
            <label htmlFor="departamento">Departamento</label>
          </div>
        </div>
        <div>
        <Accordion className='m-auto mt-2' activeIndex={0} style={{maxWidth: '850px'}}>
          <AccordionTab header="Categorías">
            <div className="d-flex flex-wrap justify-content-evenly">
              {edadesRanking.map((ed, indexEdad)=>{
                return <div key={indexEdad} className="d-flex align-items-center" style={{width: '180px'}}>
                <Checkbox inputId={ed.edad} name="pizza" value={ed.edad} onChange={sacarEdadesElegidas} checked={opcionesElegidos.includes(ed.edad)}/>
                <label htmlFor={ed.edad} className="ml-2">{ed.edad + ' Años'}</label>
              </div>
              })}
            </div>
          </AccordionTab>
        </Accordion>
        </div>
        {resultadoBusqueda.length !== 0 && tiempos.length !== 0 ?(
          <DataTable value={resultadoBusqueda} selectionMode="single" dragSelection size='small' stripedRows paginator rows={8} rowsPerPageOptions={[8, 10, 25, 50]} emptyMessage="No hay resultados aún." 
          tableStyle={{ minWidth: '50rem', margin: 'auto'}}>
            <Column header="Pto" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
            <Column header="Equipo" body={(rank) => `${(rank.club) + '/' + (reducirPalabraDepartamento(rank.departamento))}`} style={{ width: '180px' }}></Column>
            <Column field="nombre" header="Deportista" style={{ width: '200px', fontWeight: 'bold' }}></Column>
            <Column header="Puntos" body={(_, indexRank) => `${tiempos[indexRank.rowIndex].pa}`} style={{ width: '50px'}}></Column>
            <Column header="Fecha Evento" body={(_, indexRank) => `${tiempos[indexRank.rowIndex].fecha}`} style={{ width: '130px'}}></Column>
            <Column header="Tiempo" body={(_, indexRank) => `${arreglarTiempoEstandar(tiempos[indexRank.rowIndex].record)}`} style={{ width: '70px'}}></Column>
          </DataTable>
        ):(
          <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{duration: 1}}
          className='w-75 m-auto bg-light'
          style={{borderRadius: '10px'}}
          >
            <div className='p-5'>
              <img className='w-25' src={require('../img/img-logo-swimmingt.webp')} alt="logo prowebsports" />
              <h1>¡Bienvenido!</h1>
              <p>No tenemos resultados aún.</p>
            </div>
          </motion.div>
        )}
      </motion.div>
    </>
  )
}

export default Ranking;