import { pruebasAElegir } from "./administrador";

export function coloresCategoria(categoria){
  let color = '';
  const auxColor = pruebasAElegir.filter((prue)=>{return prue.valor === categoria});
  if(auxColor.length !== 0){
    color = auxColor[0].color;
  }
  return color;
}

export function acomodarTextoCategoria(categorias){
  let text = [];
  categorias.forEach((categoria)=>{
    const auxText = pruebasAElegir.filter((prue)=>{return prue.valor === categoria});
    if(auxText.length !== 0){
      text.push(auxText[0].nombre + '/');
    }
  })
  return text;
}