import React from 'react';

const SorteoInterno = () => {

  function aleatorio() {
    const auxLimite = document.getElementById('limite') ? document.getElementById('limite').value ? document.getElementById('limite').value : 0 : 0;
    let num = Math.floor(Math.random() * (1 + parseInt(auxLimite) - 1) + 1);
    return num;
  }

  function clickCirculo1(){
    const intento1 = document.getElementById("intento-1");
    intento1.style.backgroundColor = "#F17474";
    const numeros = setInterval(function () {
       intento1.innerHTML = aleatorio(); 
    },100);
    setTimeout(function () {
        intento1.innerHTML = aleatorio();
        clearInterval(numeros);
    }, 5000);
    intento1.style.color = "white";
    intento1.style.animation = "sombraRoja 1s infinite alternate";
    intento1.style.animationDelay = "5.5s";
    intento1.style.border = "1px solid red";
  }

  function clickCirculo2(){
    const intento2 = document.getElementById("intento-2")
    intento2.style.backgroundColor = "#F17474";
    const numeros = setInterval(function () {
      intento2.innerHTML = aleatorio();
    }, 100);
    setTimeout(function () {
      intento2.innerHTML = aleatorio();
      clearInterval(numeros);
    }, 5000);
    intento2.style.color = "white";
    intento2.style.animation = "sombraRoja 1s infinite alternate";
    intento2.style.animationDelay = "5.5s";
    intento2.style.border = "1px solid red";
  }

  function clickCirculo3(){
    const intento3 = document.getElementById("intento-3")
    intento3.style.backgroundColor = "#50F08C";
    const numeros = setInterval(function () {
      intento3.innerHTML = aleatorio();
    }, 100);
    setTimeout(function () {
      intento3.innerHTML = aleatorio();
      clearInterval(numeros);
    }, 5000);
    intento3.style.color = "white";
    intento3.style.animation = "sombraVerde 1s infinite alternate";
    intento3.style.animationDelay = "5.5s";
    intento3.style.border = "1px solid green";
  }

  return (
    <div className='bg-light p-2'>
      <div className='m-auto pt-2' style={{maxWidth: '1000px'}}>
        <div className='m-auto' style={{maxWidth: '150px'}}>
          <input type="number" id='limite' className='form-control mb-2 text-center' placeholder='¿Limite?' />
        </div>
        <hr />
        <div className="d-flex flex-wrap justify-content-around">
          <div className="d-flex flex-column">
              <h3 className="text-danger">NO JUEGA</h3>
              <label className="intento d-flex align-items-center justify-content-center bg-danger text-white shadow" style={{borderRadius: '50%', width: '150px', height: '150px', fontSize: '48px', textShadow: '0 0 2px black', border: '1px solid red', cursor: 'pointer'}} id="intento-1" onClick={() => clickCirculo1()}>0</label>
          </div>
          <div className="d-flex flex-column">
              <h3 className="text-danger">NO JUEGA</h3>
              <label className="intento d-flex align-items-center justify-content-center bg-danger text-white shadow" style={{borderRadius: '50%', width: '150px', height: '150px', fontSize: '48px', textShadow: '0 0 2px black', border: '1px solid red', cursor: 'pointer'}} id="intento-2" onClick={() => clickCirculo2()}>0</label>
          </div>
          <div className="d-flex flex-column">
              <h3 className="text-success">GANADOR</h3>
              <label className="intento d-flex align-items-center justify-content-center bg-success text-white shadow" style={{borderRadius: '50%', width: '150px', height: '150px', fontSize: '48px', textShadow: '0 0 2px black', border: '1px solid green', cursor: 'pointer'}} id="intento-3" onClick={() => clickCirculo3()}>0</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SorteoInterno;