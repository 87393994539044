import { categorias } from './administrador';

export function acomodarEdadesMaster(edad){
  let text = '';
  switch (edad) {
    case 25:
      text = '25 - 29'
      break;
    case 30:
      text = '30 - 34'
      break;
    case 35:
      text = '35 - 39'
      break;
    case 40:
      text = '40 - 44'
      break;
    case 45:
      text = '45 - 49'
      break;
    case 50:
      text = '50 - 54'
      break;
    case 55:
      text = '55 - 59'
      break;
    case 60:
      text = '60 - 64'
      break;
    case 65:
      text = '65 - 69'
      break;
    case 70:
      text = '70 - 74'
      break;
    case 75:
      text = '75 - 79'
      break;
    case 80:
      text = '80 - 84'
      break;
  
    default:
      break;
  }
  return text;
}

export function acmodarEdadesRelevosMaster(edad){
  let text = '';
  switch (edad) {
    case 120:
      text = '120 - 159'
      break;
    case 160:
      text = '160 - 199'
      break;
    case 200:
      text = '200 - 239'
      break;
    case 240:
      text = '240 - 279'
      break;
    case 280:
      text = '280 - 310'
      break;
    default:
      break;
  }
  return text;
}

export function acomodarEdadesPersonalizadas(edad){
  let text = edad;
  switch (edad[0]) {
    case 17:
      text = '17 a 23'
      break;
    default:
      break;
  }
  return text;
}

export function completarPruebas(torneoEspecifico, listPruebas) {
  let auxPruebas = [];
  let aux = [];
  let contador = 1;

  if (torneoEspecifico.length !== 0) {
    torneoEspecifico.jornadas.forEach((torn) => {
      torn.categoria.forEach((cate) => {
        const categoria = categorias.find((c) => c.nombre === cate);
        if (categoria && torn.pruebas.length !== 0) {
          switch (cate) {
            case 'menoresJuntos':
              cate = 'menores';
              break;
            case 'infantilAJuntos':
              cate = 'infantilA';
              break;
            case 'infantilBJuntos':
              cate = 'infantilB';
              break;
            case 'juvenilAJuntos':
              cate = 'juvenilA';
              break;
            case 'juvenilBJuntos':
              cate = 'juvenilB';
              break;
            default:
              break;
          }
          const auxListPruebas = torn.pruebas.flatMap((prue) =>
          listPruebas.filter((list) => prue.idPrueba === list.id));
          if(categoria.nombre === 'infantilA' || 
          categoria.nombre === 'infantilB' || 
          categoria.nombre === 'juvenilA' || 
          categoria.nombre === 'juvenilB' ||
          categoria.nombre === 'menores' ||
          categoria.nombre === 'mayores' ||
          categoria.nombre === 'relevosMenores' ||
          categoria.nombre === 'relevosInfantilA' ||
          categoria.nombre === 'relevosInfantilB' ||
          categoria.nombre === 'relevosJuvenilA' ||
          categoria.nombre === 'relevosJuvenilB' ||
          categoria.nombre === 'relevosMayores' ||
          categoria.nombre === 'relevosMaster'){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                listPrueba.requisitos.forEach((req)=>{
                  categoriaDeportista = categoria.arreglo.filter((dep)=>{
                    return parseInt(req.edad) === dep.edad && req.genero === dep.genero 
                  })  
                  categoriaDeportista.forEach((deportista) => {
                    const auxPrueba = {
                      id: contador++,
                      categoria: `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} ${deportista.edad} años ${deportista.genero === 'Femenino' ? 'F' : 'M'}`,
                      prueba: {
                        id: listPrueba.id,
                        prueba: listPrueba.prueba,
                      },
                      edad: deportista.edad,
                      genero: deportista.genero,
                      deportistas: [],
                    };
    
                    switch (categoria.nombre) {
                      case 'mayores':
                        auxPrueba.categoria = `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} Mayores ${deportista.genero === 'Femenino' ? 'F' : 'M'}`;
                        break;
                      case 'relevosMayores':
                        auxPrueba.categoria = `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} Mayores ${deportista.genero === 'Femenino' ? 'F' : 'M'}`;
                        break;
                      default:
                        break;
                    }
                    auxPruebas.push(auxPrueba);
                  });
                })
              }
            });
          }
          if(categoria.nombre === 'abierta'){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                listPrueba.requisitos.forEach((req)=>{
                  categoriaDeportista = categoria.arreglo.filter((dep)=>{
                    return req.edad === dep.edad && req.genero === dep.genero 
                  })
                  categoriaDeportista.forEach((deportista) => {
                    const auxPrueba = {
                      id: contador++,
                      categoria: `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} Abierta ${deportista.genero === 'Femenino' ? 'F' : 'M'}`,
                      prueba: {
                        id: listPrueba.id,
                        prueba: listPrueba.prueba,
                      },
                      edad: deportista.edad,
                      genero: deportista.genero,
                      deportistas: [],
                    };
                    auxPruebas.push(auxPrueba);
                  });
                })
              }
            });
          }
          if(categoria.nombre === 'master'){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                categoriaDeportista = categoria.arreglo.filter((dep)=>{
                  return (((dep.edad >= 25 || dep.edad <= 84) && dep.genero === 'Femenino') || 
                  ((dep.edad >= 25 || dep.edad <= 84) && dep.genero === 'Masculino')) 
                })
                categoriaDeportista.forEach((deportista) => {
                  const auxPrueba = {
                    id: contador++,
                    categoria: `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} ${acomodarEdadesMaster(deportista.edad)} Años ${deportista.genero === 'Femenino' ? 'F' : 'M'}`,
                    prueba: {
                      id: listPrueba.id,
                      prueba: listPrueba.prueba,
                    },
                    edad: deportista.edad,
                    genero: deportista.genero,
                    deportistas: [],
                  };
                  auxPruebas.push(auxPrueba);
                });
              }
            });
          }

          if(categoria.nombre === 'relevosMixtoMenores' ||
          categoria.nombre === 'relevosMixtoInfantilA' ||
          categoria.nombre === 'relevosMixtoInfantilB' ||
          categoria.nombre === 'relevosMixtoJuvenilA' ||
          categoria.nombre === 'relevosMixtoJuvenilB' ||
          categoria.nombre === 'relevosMixtoMayores' ||
          categoria.nombre === 'relevosMixtoMaster'
          ){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                categoriaDeportista = categoria.arreglo.filter((dep)=>{
                  return (((dep.edad === 6 || 
                    dep.edad === 7 || 
                    dep.edad === 8 || 
                    dep.edad === 9 || 
                    dep.edad === 10 ||
                    dep.edad === 11 ||
                    dep.edad === 12 ||
                    dep.edad === 13 ||
                    dep.edad === 14 ||
                    dep.edad === 15 ||
                    dep.edad === 16 ||
                    dep.edad === 17 ||
                    dep.edad === 18 ||
                    dep.edad === 120 ||
                    dep.edad === 160 ||
                    dep.edad === 200 ||
                    dep.edad === 240 ||
                    dep.edad === 280
                    ) && dep.genero === 'Mixto')) 
                })
                //falta relevos mayores y master
                categoriaDeportista.forEach((deportista) => {
                  const auxPrueba = {
                    id: contador++,
                    categoria: `${deportista.edad === 18 ? 'Mayores Mixto' : deportista.edad + ' Años Mixto'}`,
                    prueba: {
                      id: listPrueba.id,
                      prueba: listPrueba.prueba,
                    },
                    edad: deportista.edad,
                    genero: deportista.genero,
                    deportistas: [],
                  };

                  switch (categoria.nombre) {
                    case 'relevosMixtoMenores':
                      auxPrueba.categoria = `${deportista.edad + ' Años Mixto'}`;
                      break;
                    case 'relevosMixtoInfantilA':
                      auxPrueba.categoria = `${deportista.edad + ' Años Mixto'}`;
                      break;
                    case 'relevosMixtoInfantilB':
                      auxPrueba.categoria = `${deportista.edad + ' Años Mixto'}`;
                      break;
                    case 'relevosMixtoJuvenilA':
                      auxPrueba.categoria = `${deportista.edad + ' Años Mixto'}`;
                      break;
                    case 'relevosMixtoJuvenilB':
                      auxPrueba.categoria = `${deportista.edad + ' Años Mixto'}`;
                      break;
                    case 'relevosMixtoMaster':
                      auxPrueba.categoria = `${acmodarEdadesRelevosMaster(deportista.edad) + ' Años Mixto'}`;
                      break;
                    default:
                      break;
                  }
                  auxPruebas.push(auxPrueba);
                });
              }
            });
          }

          if(categoria.nombre === 'relevosMixtoMenores5y6' ||
          categoria.nombre === 'relevosMixtoMenores7y8' ||
          categoria.nombre === 'relevosMixtoMenores9y10' ||
          categoria.nombre === 'relevosMixtoInfantil11y12' ||
          categoria.nombre === 'relevosMixtoInfantil13y14' ||
          categoria.nombre === 'relevosMixtoJuvenil15y16' ||
          categoria.nombre === 'relevosMixtoJuvenil17ymayores'
          ){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                categoriaDeportista = categoria.arreglo.filter((dep)=>{
                  return (((dep.edad[0] === 5 || 
                    dep.edad[1] === 6 || 
                    dep.edad[0] === 7 || 
                    dep.edad[1] === 8 || 
                    dep.edad[0] === 9 ||
                    dep.edad[1] === 10 ||
                    dep.edad[0] === 11 ||
                    dep.edad[1] === 12 ||
                    dep.edad[0] === 13 ||
                    dep.edad[1] === 14 ||
                    dep.edad[0] === 15 ||
                    dep.edad[1] === 16 ||
                    dep.edad[0] === 17
                    ) && dep.genero === 'Mixto')) 
                })
                categoriaDeportista.forEach((deportista) => {
                  const auxPrueba = {
                    id: contador++,
                    categoria: `${acomodarEdadesPersonalizadas(deportista.edad) + ' Años Relevo Mixto'}`,
                    prueba: {
                      id: listPrueba.id,
                      prueba: listPrueba.prueba,
                    },
                    edad: deportista.edad,
                    genero: deportista.genero,
                    deportistas: [],
                  };
                  auxPruebas.push(auxPrueba);
                });
              }
            });
          }
          if(categoria.nombre === 'menoresJuntos' ||
          categoria.nombre === 'infantilAJuntos' || 
          categoria.nombre === 'infantilBJuntos' || 
          categoria.nombre === 'juvenilAJuntos' ||
          categoria.nombre === 'juvenilBJuntos'){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                categoriaDeportista = categoria.arreglo.filter((dep)=>{
                  return ((7 === dep.edad[0] || 8 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (8 === dep.edad[0] || 9 === dep.edad[1]) && dep.genero === 'Masculino') ||

                  ((10 === dep.edad[0] || 11 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (11 === dep.edad[0] || 12 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((12 === dep.edad[0] || 13 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (13 === dep.edad[0] || 14 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((14 === dep.edad[0] || 15 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (15 === dep.edad[0] || 16 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((16 === dep.edad[0] || 17 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (17 === dep.edad[0] || 18 === dep.edad[1]) && dep.genero === 'Masculino')
                })  
                categoriaDeportista.forEach((deportista) => {
                  const auxPrueba = {
                    id: contador++,
                    categoria: `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} ${deportista.edad[0]} - ${deportista.edad[1]} años ${deportista.genero === 'Femenino' ? 'F' : 'M'}`,
                    prueba: {
                      id: listPrueba.id,
                      prueba: listPrueba.prueba,
                    },
                    edad: deportista.edad,
                    genero: deportista.genero,
                    deportistas: [],
                  };

                  auxPruebas.push(auxPrueba);
                });
              }
            });
          }

          if(categoria.nombre === 'menores5y6' ||
          categoria.nombre === 'menores7y8' || 
          categoria.nombre === 'menores9y10' || 
          categoria.nombre === 'infantil11y12' ||
          categoria.nombre === 'infantil13y14' ||
          categoria.nombre === 'juvenil15y16' ||
          categoria.nombre === 'juvenil17ymayores'){
            auxListPruebas.forEach((listPrueba) => {
              if(listPrueba.categoria === cate){
                let categoriaDeportista = [];
                categoriaDeportista = categoria.arreglo.filter((dep)=>{
                  return ((5 === dep.edad[0] || 6 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (5 === dep.edad[0] || 6 === dep.edad[1]) && dep.genero === 'Masculino') ||

                  ((7 === dep.edad[0] || 8 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (7 === dep.edad[0] || 8 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((9 === dep.edad[0] || 10 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (9 === dep.edad[0] || 10 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((11 === dep.edad[0] || 12 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (11 === dep.edad[0] || 12 === dep.edad[1]) && dep.genero === 'Masculino') ||
  
                  ((13 === dep.edad[0] || 14 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (13 === dep.edad[0] || 14 === dep.edad[1]) && dep.genero === 'Masculino') ||

                  ((15 === dep.edad[0] || 16 === dep.edad[1]) && dep.genero === 'Femenino' ||
                  (15 === dep.edad[0] || 16 === dep.edad[1]) && dep.genero === 'Masculino') ||

                  ((17 === dep.edad[0]) && dep.genero === 'Femenino' ||
                  (17 === dep.edad[0]) && dep.genero === 'Masculino')
                })  
                categoriaDeportista.forEach((deportista) => {
                  const auxPrueba = {
                    id: contador++,
                    categoria: `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} ${deportista.edad.length !== 1 ? (deportista.edad[0] + ' - ' + deportista.edad[1]): deportista.edad[0]} años ${deportista.genero === 'Femenino' ? 'F' : 'M'}`,
                    prueba: {
                      id: listPrueba.id,
                      prueba: listPrueba.prueba,
                    },
                    edad: deportista.edad,
                    genero: deportista.genero,
                    deportistas: [],
                  };

                  switch (categoria.nombre) {
                    case 'juvenil17ymayores':
                      auxPrueba.categoria = `${deportista.genero === 'Femenino' ? '(F)' : '(M)'} ${deportista.edad[0]} - ${deportista.edad[deportista.edad.length - 1]} años ${deportista.genero === 'Femenino' ? 'F' : 'M'}`;
                      break;
                    default:
                      break;
                  }
                  auxPruebas.push(auxPrueba);
                });
              }
            });
          }
        }
      });
    });
    torneoEspecifico.jornadas.forEach((tor)=>{
      // Crear un nuevo arreglo grande organizado según el arreglo pequeño
      const arregloOrganizado = [];
      tor.pruebas.forEach((objPequeño) => {
        const objetosCorrespondientes = auxPruebas.filter(objGrande => objGrande.prueba.prueba === objPequeño.prueba && objGrande.prueba.id === objPequeño.idPrueba);
        arregloOrganizado.push(...objetosCorrespondientes);
      });
      aux.push(arregloOrganizado)
    })
    let contadorr = 1;
    for (let i = 0; i < aux.length; i++) {
      for (let j = 0; j < aux[i].length; j++) {
         aux[i][j].id = contadorr;
         contadorr++;
       }
    }
  }

  return aux;
} 