import React, { useState } from "react";
import { login, restablecerPassword } from "../controllers/configuracionFirebase";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { datosAdmin } from "../controllers/administrador";

const Login = ({ setLogin, loginv, setDialogResultados }) => {

  const [contrasena, setContrasena] = useState('');

  /* la funcion clickLogin nos ayudara a pasar al otro formulario que es registrarse. */
  function clickLogin() {
    setLogin(false);
  }

  /* la funcion onSubmit traera todos los datos que se han ingresado en el login,
  y asi mismo validara si las credenciales son correctas o no. */
  function onSubmit(e) {
    e.preventDefault();

    const correo = e.target.email.value;
    const password = contrasena;
    login(correo, password);
    setContrasena('');
    e.target.reset();
  }

  /* la funcion onSubmitRestablecerPassword nos ayudara a traer el email, una vez con el email
  se le enviara un correo a ese email con un link donde podra restablecer la contraseña en caso de que se le haya
  olvidado.*/
  function onSubmitRestablecerPassword(e) {
    e.preventDefault();

    const correo = e.target.email.value;
    restablecerPassword(correo);
    
    e.target.reset();
  }

  return (
    <div style={{ width: "300px" }}>
      <form
        className={
          loginv
            ? "column g-3 was-validated form-prime"
            : "column g-3 was-validated form-login-inativo form-prime"
        }
        onSubmit={onSubmit}
      >
        <div className="col-auto mb-2">
          <img
            className="logo-pantalla-media m-auto"
            style={{ width: "50vw" }}
            src={require("../img/img_logo.png")}
            alt="logo"
          />
        </div>
        <div className="alert submit bg-gradient show mb-1" role="alert">
          <h1>
            ¡BIENVENIDO!
          </h1>
          <p className="mb-0">
          A la plataforma profesional del <br /> <strong>CLUB DE NATACIÓN {datosAdmin.nombre}.</strong>
          </p>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-at"></i>
          </span>
          <InputText type="email" name="email" placeholder="E-mail@example.com" className="p-2" required/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena.length >= 6 ? 'pi pi-unlock' : 'pi pi-lock'}></i>
          </span>
          <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
          className={`${contrasena !== '' && contrasena.length >= 6 ? '' : 'p-invalid'}`}
          feedback={false}/>
        </div>
        <div className="col-auto d-flex">
          <p className="mb-2">
            <button
              type="button"
              className="link"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              ¿Has olvidado tu contraseña?
            </button>
          </p>
        </div>
        <div className="col-auto mb-2">
          <Button type="submit" icon='pi pi-key' label="ACCESO" className="btn submit w-100 mb-1"></Button>
          <Button type="button" icon='pi pi-stopwatch' label="RESULTADOS" className="btn btn-success w-100" onClick={() => setDialogResultados(true)}></Button>
        </div>
        <div className="col-auto">
          <p>
              No tienes una cuenta?{" "}
            <button type="button" className="link" onClick={clickLogin}>   
              Inscribirse
            </button>
          </p>
        </div>
      </form>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Restaurar contraseña
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={onSubmitRestablecerPassword}
                className="column g-3"
              >
                <img
                  className="w-50 mb-2"
                  src={require("../img/img-logo-swimmingt.webp")} 
                  alt="logo de prowebsports"
                />
                <input
                  type="email"
                  placeholder="email@gmail.com"
                  name="email"
                  className="form-control"
                  required
                />
                <input
                  type="submit"
                  value="Enviar"
                  className="btn submit mt-2 w-100"
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
