import QRCodeStyling from 'qr-code-styling';
import { datosAdmin } from './administrador';

export function generarQrCode(){
  const container = document.getElementById('qr-code');
  const containerWidth = container.offsetWidth;
  const qr = new QRCodeStyling({
    width: containerWidth - 100,
    height: containerWidth - 100,
    data: datosAdmin.enlace,
    image: require('../img/img_logo.png'), // Ruta de la imagen a superponer
    imageOptions: {
      crossOrigin: 'anonymous', // Opcional: para permitir el uso de la imagen desde otro dominio
      margin: 0, // Margen alrededor del logotipo
    },
    dotsOptions: {
      type: "classy-rounded",
      gradient: {colorStops: [
        {offset: 0, color: 'rgb(0, 157, 255)'}, 
        {offset: 1, color: 'rgba(0, 82, 133)'},
      ], type: 'linear', rotation: 10}
    },
    margin: 15
  });

  return qr;
}

export function generarQrCodeMayorCalidad(){
  const qr = new QRCodeStyling({
    width: 1024,
    height: 1024,
    data: datosAdmin.enlace,
    image: require('../img/img_logo.png'), // Ruta de la imagen a superponer
    imageOptions: {
      crossOrigin: 'anonymous', // Opcional: para permitir el uso de la imagen desde otro dominio
      margin: 0, // Margen alrededor del logotipo
    },
    dotsOptions: {
      type: "classy-rounded",
      gradient: {colorStops: [
        {offset: 0, color: 'rgb(0, 157, 255)'}, 
        {offset: 1, color: 'rgba(0, 82, 133)'},
      ], type: 'linear', rotation: 10}
    },
    margin: 15
  });

  return qr;
}