import React, { useState, useEffect } from "react";
import { buscarNombresListado, registrarUsuario } from '../controllers/configuracionFirebase';
import { toast } from "react-hot-toast";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';

const Registrar = ({ setLogin, loginv }) => {

  const [ nombres, setNombres ] = useState([]);
  const [selectedClub, setSelectedClub] = useState(null);
  const [contrasena, setContrasena] = useState('');
  const [validarContrasena, setValidarContrasena] = useState('');

  /* la funcion clickRegistrarse nos ayudara a pasar al otro formulario que es Login. */
  function clickRegistrarse() {
    setLogin(true);
  }

  /* la funcion onSubmit traera todos los datos que se han ingresado al registrarse,
  y asi mismo validara si las credenciales son correctas o no. 
  hay que tener en cuenta que esto solo dejara registrar a los usuarios que tengan correos como:*/
  function onSubmit(e) {
    e.preventDefault();

    const nombreclub = selectedClub;
    const correo = e.target.email.value;
    const password = contrasena
    const passwordValidar = validarContrasena
    if(nombreclub){
      if(password === passwordValidar){
        registrarUsuario(correo, password, nombreclub);
        setSelectedClub(null);
        setContrasena('');
        setValidarContrasena('');
        e.target.reset();
      }else{
        toast.error('Las contraseñas no coinciden, por favor verifica.');
      }
    }else{
      toast.error('Para registrarse, es necesario elegir el nombre del club.');
    }
  }

  useEffect(() => {
    buscarNombresListado(setNombres);
  }, [setNombres])

  return (
    <div style={{ width: "300px" }}>
      <form
        className={
          loginv
            ? "column g-3 was-validated form-registrar-inativo form-prime"
            : "column g-3 was-validated form-prime"
        }
        onSubmit={onSubmit}
      >
        <div className="col-auto mb-4">
          <img
            className="logo-pantalla-media m-auto"
            style={{ width: "50vw" }}
            src={require("../img/img_logo.png")}
            alt="logo prowebsports"
          />
        </div>
        <div className="alert submit bg-gradient show mb-1" role="alert">
          <h1>
            ¡BIENVENIDO!
          </h1>
          <p className="mb-0">
            Regístrate Aquí
          </p>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-users"></i>
          </span>
          <Dropdown value={selectedClub} onChange={(e) => setSelectedClub(e.value)} filter options={nombres} placeholder={nombres.length + ' Clubs Disponibles'} className={`m-0 ${selectedClub ? '' : 'p-invalid'}`}/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-at"></i>
          </span>
          <InputText type="email" name="email" placeholder="E-mail@example.com" className="p-2" required/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena !== '' && contrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
          className={`${contrasena !== '' && contrasena.length >= 6 ? '' : 'p-invalid'}`}
          promptLabel="Min. 6 Caracteres"
          weakLabel="Débil"
          mediumLabel="Media"
          strongLabel="Fuerte"/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={validarContrasena} onChange={(e) => setValidarContrasena(e.target.value)} placeholder="Validar Contraseña" toggleMask minLength={6} required
          className={`${contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? '' : 'p-invalid'}`}
          feedback={false}/>
        </div>
        <div className="col-auto">
          <Button type="submit" icon='pi pi-user' label="CREAR UNA CUENTA" className="btn submit w-100 mb-2"></Button>
        </div>
        <div className="col-auto">
          <p>
              ¿Ya tienes una cuenta?{" "}
            <button type="button" className="link" onClick={clickRegistrarse}>
              Iniciar sesión
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Registrar;