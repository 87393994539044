import React, { useState, useEffect } from 'react';
import TablaPlanilla from './TablaPlanilla'
import { motion } from "framer-motion";
import { toast } from 'react-hot-toast';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { pdfPlanilla } from '../controllers/pdfs';
import Conctatenos from './Conctatenos';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';
import { Button } from 'primereact/button';

const PlanillaInvitado = ({ 
  idTorneoParticipando,
  pruebasCompletas, 
  torneoEspecifico,
  planilla,
  usuario,
  fechaRegistroProgramacion}) => {

  const [ nadadoresCarriles, setNadadoresCarriles ] = useState([]);
  const [ pdfUrl, setPdfUrl] = useState();

  function descargar(){
    saveAs(pdfUrl, `programacion ${datosAdmin.nombre}.pdf`);
  }

  const items = [
    {
      label: 'Programación .pdf Marcada',
      icon: 'pi pi-palette',
      command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, true)
    },
    {
        label: 'Programación .pdf',
        icon: 'pi pi-file-pdf',
        command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, false)
    },
  ];

  function sacarDeportistas(){
    setNadadoresCarriles(planilla);
  }

  useEffect(() => {
    sacarDeportistas();
  }, [])

  return (
    <div className='position-relative'>
      <motion.div
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="alert alert-info alert-dismissible fade show text-center mb-0" role="alert">
          <h4>¡ULTIMO REGISTRO PROGRAMACIÓN!</h4>
          <p className="mb-0">{fechaRegistroProgramacion}</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className="alert alert-success alert-dismissible fade show text-start" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">Este espacio está destinado para que puedas ver la programación con tus deportistas marcados y así estar al tanto de su participación. Sin embargo, ten en cuenta que debes esperar a que el anfitrión registre la programación. Si aún no ves a tus deportistas, es probable que el anfitrión no haya actualizado la programación. No descargues la programación hasta que el anfitrión la haya confirmado.</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        {pruebasCompletas.lenght !== 0 ?(
          pruebasCompletas.map((prue, indexPrue)=>{
            return <TablaPlanilla key={indexPrue} jornada={indexPrue + 1} pruebas={prue} nadadores={nadadoresCarriles.length !== 0 ? nadadoresCarriles : []} idTorneoParticipando={idTorneoParticipando} invitado={true} usuario={usuario}/>
          })
          ):(
            <></>
          )}
      </motion.div>
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={180} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Programación`} modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
          <Button icon="pi pi-download" label='Descargar Programación' className='p-2 btn btn-success' onClick={() => descargar()}></Button>
        </div>
      </Dialog>
      <Conctatenos/>
    </div>
  )  
}

export default PlanillaInvitado;