import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { datosAdmin } from './administrador';

export function facturacion(club, torneoEspecifico, setPdfUrl, setDescripcion){
    const doc = new jsPDF({
      format: 'letter',
    }); 

    doc.internal.pageSize.height = 146;
    // Agregar el logo de la empresa como marca de agua
    const logoImg = new Image();
    logoImg.src = require('../img/marca-de-agua.png');
    const width = 160;
    const height = 160;
    doc.addImage(logoImg, 'png', 27, 0, width, height);

    // Configurar la fuente y el tamaño
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(11);
    
    // Título del torneo
    doc.text(`${torneoEspecifico.titulo}\nCLUB DEPORTIVO ESCUELA DE NATACIÓN ${datosAdmin.nombre}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFont('arial');
    doc.setFontSize(20);
    doc.text(`FACTURA`, doc.internal.pageSize.width / 2, 21, { align: 'center' });
    doc.line(20, 23, 190, 23);
    doc.line(20, 140, 190, 140);

    //NIT prowebsports
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`NIT: 6253227-2`, doc.internal.pageSize.width / 2, 27, { align: 'center' });

    //factura Cliente
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Factura a:`, 20, 40, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.nombreclub}`, 38, 40, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 20, 44, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.email}`, 33, 44, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 20, 48, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.cel ? club.cel : '--- --- ----'}`, 34, 48, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Torneo:`, 20, 52, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${torneoEspecifico.fechaTorneo.replace(/-/g, "/")}`, 46, 52, { align: 'left' });
    doc.line(20, 54, 190, 54);
    doc.line(20, 270, 190, 270);

    //fecha torneo
    const hoy = new Date();
    const fechaString = hoy.toLocaleDateString();
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Factura:`, 157, 52, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`${fechaString}`, 176, 52, { align: 'right' });

    const totalAPagar = (parseInt(torneoEspecifico.valorClub) * 1) + (parseInt(torneoEspecifico.valorDeportista) * club.nadadores.length);
    const precioClub = parseInt(torneoEspecifico.valorClub);
    const precioDeport = parseInt(torneoEspecifico.valorDeportista);
    // Definir datos de la tabla
    const tabla = [
      ['$ Club', '$ Deportista', 'N. Club', 'N. Deportistas', 'Total'],
      [`$${precioClub.toLocaleString()}`, `$${precioDeport.toLocaleString()}`, `1`, `${club.nadadores.length}`, `$${totalAPagar.toLocaleString()}`],
    ];

    // Configurar la posición de la tabla
    const startX = 18;
    const startY = 60;
    const cellWidth = 35;
    const cellHeight = 10;

    doc.setFont('arial', '', '');
    doc.setFontSize(11);
    // Dibujar los bordes de la tabla y las celdas
    for (let i = 0; i < tabla.length; i++) {
      for (let j = 0; j < 5; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(200, 200, 200); // Fondo de encabezado
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight);
          }
      }
    }

    // Dibujar el contenido de la tabla
    doc.setFont('arial', '', '');
    for (let i = 0; i < tabla.length; i++) {
        for (let j = 0; j < 5; j++) {
            doc.text(tabla[i][j], startX + j * cellWidth + 2, startY + i * cellHeight + cellHeight / 2);
        }
    }

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`Total a pagar:`, 20, 90, { align: 'left' });

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`COP $${totalAPagar.toLocaleString()}`, 190, 90, { align: 'right' });
    doc.line(20, 85, 190, 85);
    doc.line(20, 92, 190, 92);

    //Formas de pago
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(20);
    doc.text(`FORMA DE PAGO`, doc.internal.pageSize.width / 2, 105, { align: 'center' });
    doc.line(20, 106, 190, 106);

    //cuenta nequi y daviplata
    doc.setFont('arial', 'italic', '');
    doc.setFontSize(12);
    doc.text(`Cta Ahorros Bancolombia:`, 20, 110, { align: 'left' });
    doc.text(`866-000046-19`, 20, 115, { align: 'left' });

    // Agregar el logo de la empresa prowebsports
    const imgPws = new Image();
    imgPws.src = require('../img/img-logo-factura.png');
    const widthPws = 23;
    const heightPws = 20;
    doc.addImage(imgPws, 'png', 12, 2, widthPws, heightPws);

    //parte de abajo del pdf
    doc.setFontSize(8);
    doc.setFont('arial', 'italic');
    doc.text(`www.prowebsports.com.co`, 20, 143)
    doc.text(`pws@prowebsports.com.co`, 160, 143)
    doc.text(`PROWEBSPORTS`, 84, 143)
    doc.text(`+57 324-588-4041`, 106, 143)
    doc.setProperties({
      title: "Factura PROWEBSPORTS"
    });

    // Mostrar el PDF en una nueva ventana del navegador
    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setDescripcion('Factura');
    setPdfUrl(pdfUrl);
    saveAs(pdfUrl, `Factura de participacion ${torneoEspecifico.titulo}.pdf`);
  }

  export function facturacionPagado(club, torneoEspecifico, setPdfUrl, setDescripcion){
    const doc = new jsPDF({
      format: 'letter',
    }); 

    doc.internal.pageSize.height = 106;
    // Agregar el logo de la empresa como marca de agua
    const logoImg = new Image();
    logoImg.src = require('../img/marca-de-agua.png');
    const width = 110;
    const height = 110;
    doc.addImage(logoImg, 'png', 53, 0, width, height);

    // Configurar la fuente y el tamaño
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(11);
    
    // Título del torneo
    doc.text(`${torneoEspecifico.titulo}\nCLUB DEPORTIVO ESCUELA DE NATACIÓN ${datosAdmin.nombre}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFont('arial');
    doc.setFontSize(20);
    doc.text(`FACTURA`, doc.internal.pageSize.width / 2, 21, { align: 'center' });
    doc.line(20, 23, 190, 23);
    doc.line(20, 102, 190, 102);

    //NIT prowebsports
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`NIT: 6253227-2`, doc.internal.pageSize.width / 2, 27, { align: 'center' });

    //factura Cliente
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Factura a:`, 20, 40, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.nombreclub}`, 38, 40, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 20, 44, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.email}`, 33, 44, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 20, 48, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${club.cel ? club.cel : '### ### ####'}`, 34, 48, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Torneo:`, 20, 52, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${torneoEspecifico.fechaTorneo.replace(/-/g, "/")}`, 46, 52, { align: 'left' });
    doc.line(20, 54, 190, 54);

    //fecha torneo
    const hoy = new Date();
    const fechaString = hoy.toLocaleDateString();
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Factura:`, 157, 52, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`${fechaString}`, 176, 52, { align: 'right' });

    const totalAPagar = (parseInt(torneoEspecifico.valorClub) * 1) + (parseInt(torneoEspecifico.valorDeportista) * club.nadadores.length);
    const precioClub = parseInt(torneoEspecifico.valorClub);
    const precioDeport = parseInt(torneoEspecifico.valorDeportista);
    // Definir datos de la tabla
    const tabla = [
      ['$ Club', '$ Deportista', 'N. Club', 'N. Deportistas', 'Total'],
      [`$${precioClub.toLocaleString()}`, `$${precioDeport.toLocaleString()}`, `1`, `${club.nadadores.length}`, `$${totalAPagar.toLocaleString()}`],
    ];

    // Configurar la posición de la tabla
    const startX = 18;
    const startY = 60;
    const cellWidth = 35;
    const cellHeight = 10;

    doc.setFont('arial', '', '');
    doc.setFontSize(11);
    // Dibujar los bordes de la tabla y las celdas
    for (let i = 0; i < tabla.length; i++) {
      for (let j = 0; j < 5; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(200, 200, 200); // Fondo de encabezado
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight);
          }
      }
    }

    // Dibujar el contenido de la tabla
    doc.setFont('arial', '', '');
    for (let i = 0; i < tabla.length; i++) {
        for (let j = 0; j < 5; j++) {
            doc.text(tabla[i][j], startX + j * cellWidth + 2, startY + i * cellHeight + cellHeight / 2);
        }
    }

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`Monto pagado:`, 20, 90, { align: 'left' });

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`COP $${totalAPagar.toLocaleString()}`, 190, 90, { align: 'right' });
    doc.line(20, 85, 190, 85);
    doc.line(20, 92, 190, 92);

    // Agregar el logo de la empresa prowebsports
    const imgPws = new Image();
    imgPws.src = require('../img/img-logo-factura.png');
    const widthPws = 23;
    const heightPws = 20;
    doc.addImage(imgPws, 'png', 12, 2, widthPws, heightPws);

    //parte de abajo del pdf
    doc.setFontSize(8);
    doc.setFont('arial', 'italic');
    doc.text(`www.prowebsports.com.co`, 20, 100)
    doc.text(`pws@prowebsports.com.co`, 160, 100)
    doc.text(`PROWEBSPORTS`, 84, 100)
    doc.text(`+57 324-588-4041`, 106, 100)
    doc.setProperties({
      title: "Factura PROWEBSPORTS"
    });

    // Mostrar el PDF en una nueva ventana del navegador
    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setDescripcion('Factura');
    setPdfUrl(pdfUrl);
    saveAs(pdfUrl, `Factura de participacion ${torneoEspecifico.titulo}.pdf`);
  }