export const menores = [{
  edad: 6,
  genero: 'Femenino'
},{
  edad: 7,
  genero: 'Masculino'
},{
  edad: 7,
  genero: 'Femenino'
},{
  edad: 8,
  genero: 'Masculino'
},{
  edad: 8,
  genero: 'Femenino'
},{
  edad: 9,
  genero: 'Masculino'
},{
  edad: 9,
  genero: 'Femenino'
},{
  edad: 10,
  genero: 'Masculino'
}]

export const infantilA = [{
  edad: 10,
  genero: 'Femenino'
},{
  edad: 11,
  genero: 'Masculino'
},{
  edad: 11,
  genero: 'Femenino'
},{
  edad: 12,
  genero: 'Masculino'
}]

export const infantilB = [{
  edad: 12,
  genero: 'Femenino'
},{
  edad: 13,
  genero: 'Masculino'
},{
  edad: 13,
  genero: 'Femenino'
},{
  edad: 14,
  genero: 'Masculino'
}]

export const juvenilA = [{
  edad: 14,
  genero: 'Femenino'
},{
  edad: 15,
  genero: 'Masculino'
},{
  edad: 15,
  genero: 'Femenino'
},{
  edad: 16,
  genero: 'Masculino'
}]

export const juvenilB = [{
  edad: 16,
  genero: 'Femenino'
},{
  edad: 17,
  genero: 'Masculino'
},{
  edad: 17,
  genero: 'Femenino'
},{
  edad: 18,
  genero: 'Masculino'
}]

export const mayores = [{
  edad: 18,
  genero: 'Femenino'
},{
  edad: 19,
  genero: 'Masculino'
}]

export const abierta = [{
  edad: 'catorce',
  genero: 'Femenino'
},{
  edad: 'quince',
  genero: 'Masculino'
}]

const master1 = [{
  edad: 25,
  genero: 'Femenino'
},{
  edad: 25,
  genero: 'Masculino'
}]

const master2 = [{
  edad: 30,
  genero: 'Femenino'
},{
  edad: 30,
  genero: 'Masculino'
}]

const master3 = [{
  edad: 35,
  genero: 'Femenino'
},{
  edad: 35,
  genero: 'Masculino'
}]

const master4 = [{
  edad: 40,
  genero: 'Femenino'
},{
  edad: 40,
  genero: 'Masculino'
}]

const master5 = [{
  edad: 45,
  genero: 'Femenino'
},{
  edad: 45,
  genero: 'Masculino'
}]

const master6 = [{
  edad: 50,
  genero: 'Femenino'
},{
  edad: 50,
  genero: 'Masculino'
}]

const master7 = [{
  edad: 55,
  genero: 'Femenino'
},{
  edad: 55,
  genero: 'Masculino'
}]

const master8 = [{
  edad: 60,
  genero: 'Femenino'
},{
  edad: 60,
  genero: 'Masculino'
}]

const master9 = [{
  edad: 65,
  genero: 'Femenino'
},{
  edad: 65,
  genero: 'Masculino'
}]

const master10 = [{
  edad: 70,
  genero: 'Femenino'
},{
  edad: 70,
  genero: 'Masculino'
}]

const master11 = [{
  edad: 75,
  genero: 'Femenino'
},{
  edad: 75,
  genero: 'Masculino'
}]

const master12 = [{
  edad: 80,
  genero: 'Femenino'
},{
  edad: 80,
  genero: 'Masculino'
}]

export const master = [...master1, ...master2, ...master3, ...master4, ...master5, ...master6, ...master7, ...master8,
...master9, ...master10, ...master11, ...master12];

export const menoresJuntos = [{
  edad: [7, 8],
  genero: 'Femenino'
},{
  edad: [8, 9],
  genero: 'Masculino'
}];

export const infantilAJuntos = [{
  edad: [10, 11],
  genero: 'Femenino'
},{
  edad: [11, 12],
  genero: 'Masculino'
}];

export const infantilBJuntos = [{
  edad: [12, 13],
  genero: 'Femenino'
},{
  edad: [13, 14],
  genero: 'Masculino'
}];

export const juvenilAJuntos = [{
  edad: [14, 15],
  genero: 'Femenino'
},{
  edad: [15, 16],
  genero: 'Masculino'
}];

export const juvenilBJuntos = [{
  edad: [16, 17],
  genero: 'Femenino'
},{
  edad: [17, 18],
  genero: 'Masculino'
}];

export const menores5y6 = [{
  edad: [5, 6],
  genero: 'Femenino'
},{
  edad: [5, 6],
  genero: 'Masculino'
}]

export const menores7y8 = [{
  edad: [7, 8],
  genero: 'Femenino'
},{
  edad: [7, 8],
  genero: 'Masculino'
}]

export const menores9y10 = [{
  edad: [9, 10],
  genero: 'Femenino'
},{
  edad: [9, 10],
  genero: 'Masculino'
}]

export const infantil11y12 = [{
  edad: [11, 12],
  genero: 'Femenino'
},{
  edad: [11, 12],
  genero: 'Masculino'
}]

export const infantil13y14 = [{
  edad: [13, 14],
  genero: 'Femenino'
},{
  edad: [13, 14],
  genero: 'Masculino'
}]

export const juvenil15y16 = [{
  edad: [15, 16],
  genero: 'Femenino'
},{
  edad: [15, 16],
  genero: 'Masculino'
}]

export const juvenil17ymayores = [{
  edad: [17, 18, 19, 20, 21, 22, 23],
  genero: 'Femenino'
},{
  edad: [17, 18, 19, 20, 21, 22, 23],
  genero: 'Masculino'
}]

export const relevosMixtoMenores5y6 = [{
  edad: [5, 6],
  genero: 'Mixto'
}]

export const relevosMixtoMenores7y8 = [{
  edad: [7, 8],
  genero: 'Mixto'
}]

export const relevosMixtoMenores9y10 = [{
  edad: [9, 10],
  genero: 'Mixto'
}]

export const relevosMixtoInfantil11y12 = [{
  edad: [11, 12],
  genero: 'Mixto'
}]

export const relevosMixtoInfantil13y14 = [{
  edad: [13, 14],
  genero: 'Mixto'
}]

export const relevosMixtoJuvenil15y16 = [{
  edad: [15, 16],
  genero: 'Mixto'
}]

export const relevosMixtoJuvenil17ymayores = [{
  edad: [17, 18, 19, 20, 21, 22, 23],
  genero: 'Mixto'
}]

export const relevosMixtoMenores = [{
  edad: 6,
  genero: 'Mixto'
},{
  edad: 7,
  genero: 'Mixto'
},{
  edad: 8,
  genero: 'Mixto'
},{
  edad: 9,
  genero: 'Mixto'
}]

export const relevosMixtoInfantilA = [{
  edad: 10,
  genero: 'Mixto'
},{
  edad: 11,
  genero: 'Mixto'
},{
  edad: 12,
  genero: 'Mixto'
}]

export const relevosMixtoInfantilB = [{
  edad: 13,
  genero: 'Mixto'
},{
  edad: 14,
  genero: 'Mixto'
}]

export const relevosMixtoJuvenilA = [{
  edad: 15,
  genero: 'Mixto'
},{
  edad: 16,
  genero: 'Mixto'
}]

export const relevosMixtoJuvenilB = [{
  edad: 17,
  genero: 'Mixto'
}]

export const relevosMixtoMayores = [{
  edad: 18,
  genero: 'Mixto'
}]

export const relevosMixtoMaster1 = [{
  edad: 120,
  genero: 'Mixto'
}]

export const relevosMixtoMaster2 = [{
  edad: 160,
  genero: 'Mixto'
}]

export const relevosMixtoMaster3 = [{
  edad: 200,
  genero: 'Mixto'
}]

export const relevosMixtoMaster4 = [{
  edad: 240,
  genero: 'Mixto'
}]

export const relevosMixtoMaster5 = [{
  edad: 280,
  genero: 'Mixto'
}]

export const relevosMixtoMaster = [
...relevosMixtoMaster1,
...relevosMixtoMaster2,
...relevosMixtoMaster3,
...relevosMixtoMaster4,
...relevosMixtoMaster5
]
